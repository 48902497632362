import { useTheme } from '@positivote/design-system/hooks'
import { ThemeProvider as PosiThemeProvider } from '@positivote/design-system/providers'
import { Fragment, useEffect } from 'react'

import UniversoBackground from '@/common/assets/backgrounds/universo.svg'

interface ThemeProviderProps {
  children: React.ReactNode
}

function ThemeProviderContent(props: ThemeProviderProps): JSX.Element {
  const { mode } = useTheme()

  useEffect(() => {
    const divRoot = document.getElementById('root')
    if (divRoot) {
      if (mode === 'universe') {
        divRoot.style.backgroundImage = `url("${UniversoBackground}")`
        divRoot.style.backgroundPosition = '0px -1px'
      } else {
        divRoot.style.backgroundImage = 'unset'
      }
    }
  }, [mode])

  return <Fragment {...props} />
}

export function ThemeProvider(props: ThemeProviderProps): JSX.Element {
  return (
    <PosiThemeProvider>
      <ThemeProviderContent {...props} />
    </PosiThemeProvider>
  )
}
