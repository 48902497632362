import { Div } from '@positivote/design-system/components/Div'
import { IconButton } from '@positivote/design-system/components/IconButton'
import { useState } from 'react'

import { BarChartIcon } from '@/fixme/icons/BarChart'
import { ShowChartIcon } from '@/fixme/icons/ShowChart'
import { TableChartIcon } from '@/fixme/icons/TableChart'

export interface ButtonGroupProps {
  onChangeButton: (state: number) => void
  currentPosition?: number
  type?: 'bar' | 'line'
}

export function ButtonGroup({
  onChangeButton,
  type = 'line',
  currentPosition = 0
}: ButtonGroupProps): JSX.Element {
  const [position, setPosition] = useState(currentPosition)

  function handleButton(buttonPosition: number): void {
    setPosition(buttonPosition)
    onChangeButton(buttonPosition)
  }

  return (
    <Div css={{ display: 'flex' }}>
      <IconButton
        onClick={() => handleButton(0)}
        variant="outlined"
        css={{
          borderBottomRightRadius: 0,
          borderTopRightRadius: 0,
          minWidth: 56,
          borderColor: '$outline-variant',
          borderWidth: '$thin',
          borderStyle: 'solid',

          '& .Button-StateLayer': {
            backgroundColor: position === 0 ? '$surface-5' : '$none'
          },
          '&:focus, &:active': {
            '& .Button-StateLayer': {
              backgroundColor: '$surface-5'
            }
          }
        }}
      >
        {type === 'line' ? <ShowChartIcon /> : <BarChartIcon />}
      </IconButton>
      <IconButton
        onClick={() => handleButton(1)}
        variant="outlined"
        css={{
          borderBottomLeftRadius: 0,
          borderTopLeftRadius: 0,
          minWidth: 56,
          borderColor: '$outline-variant',
          borderWidth: '$thin',
          borderStyle: 'solid',

          '& .Button-StateLayer': {
            backgroundColor: position === 1 ? '$surface-5' : '$none'
          },
          '&:focus, &:active': {
            '& .Button-StateLayer': {
              backgroundColor: '$surface-5'
            }
          }
        }}
      >
        <TableChartIcon />
      </IconButton>
    </Div>
  )
}
