import { AuthProvider } from '@/modules/hub/auth/contexts'

import { IdleTimerProvider } from './idle-timer'
import { ThemeProvider } from './theme'

export function Contexts({ children }: { children: React.ReactNode }): JSX.Element {
  return [ThemeProvider, AuthProvider, IdleTimerProvider].reduce(
    (AccumulatedProviders, CurrentProvider) =>
      // eslint-disable-next-line react/display-name
      ({ children: currentChildren }: { children: React.ReactNode }) => {
        return (
          <AccumulatedProviders>
            <CurrentProvider>{currentChildren}</CurrentProvider>
          </AccumulatedProviders>
        )
      },
    ({ children: currentChildren }) => <>{currentChildren}</>
  )({ children })
}
