import { convertDateToISO } from '@/common/helpers'

import {
  ListAccessApiResult,
  ListAccessApplicationApiResult,
  ListAccessApplicationSchoolRolesApiResult,
  ListAccessApplicationUniqueRolesApiResult,
  ListAccessUniqueApiResult,
  ListActiveApplicationsApiResult,
  ListAppsSummaryApiResult,
  ListChildrenApiResult,
  ListSchoolAccessApiResult,
  ListSchoolStudentsApiResult,
  ListSchoolUsersApiResult,
  ListUniqueApplicationApiResult,
  ListUsersManagementApiResult
} from './contracts/api'
import { BaseFilterAccess } from './contracts/form'
import {
  ListAccessApplicationSchoolRolesServiceResult,
  ListAccessApplicationUniqueRolesServiceResult,
  ListAccessApplicationUniqueServiceResult,
  ListAccessFormattedServiceResult,
  ListAccessUniqueFormattedServiceResult,
  ListActiveApplicationsServiceResultFormatted,
  ListAppsSummaryServiceResultFormatted,
  ListChildrenServiceResult,
  ListSchoolAccessServiceResult,
  ListSchoolStudentsServiceResultFormatted,
  ListSchoolUsersServiceResultFormatted,
  ListUniqueApplicationFormattedServiceResult,
  ListUsersManagementFormattedServiceResult
} from './contracts/service'

export function listAccessSummarySanitizer(
  data: ListAccessApiResult
): ListAccessFormattedServiceResult {
  return {
    ...data,
    uniqueSeriesFormatted: data.unique.map((item) => item.total),
    uniqueXaxisFormatted: data.unique.map((item) =>
      new Date(item.date + 'T00:00:00').toLocaleString(undefined, {
        day: '2-digit',
        month: '2-digit'
      })
    ),
    totalSeriesFormatted: data.total.map((item) => item.total),
    totalXaxisFormatted: data.total.map((item) =>
      new Date(item.date + 'T00:00:00').toLocaleString(undefined, {
        day: '2-digit',
        month: '2-digit'
      })
    )
  }
}

export function listUsersManagementSanitizer(
  data: ListUsersManagementApiResult[]
): ListUsersManagementFormattedServiceResult {
  return {
    seriesDateFormatted: data.map((item) => item.total),
    xaxisTotalFormatted: data.map((item) => item.date)
  }
}

export function listUniqueApplicationSanitizer(
  applications: ListUniqueApplicationApiResult
): ListUniqueApplicationFormattedServiceResult {
  return {
    ...applications,
    dataFormatted: applications.data.map((application) => ({
      ...application,
      seriesDateFormatted: application.data.map((current) => current.total), // Total de todos os itens
      xaxisTotalFormatted: application.data.map((current) =>
        new Date(current.date + 'T00:00:00').toLocaleString(undefined, {
          day: '2-digit',
          month: '2-digit'
        })
      )
    }))
  }
}

export function listAccessUniqueSanitizer(
  data: ListAccessUniqueApiResult
): ListAccessUniqueFormattedServiceResult {
  return {
    ...data,
    data: data.data.map((application) => ({
      ...application,
      date: new Date(application.date + 'T00:00:00').toLocaleString(undefined, {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
      })
    })),
    seriesTotalFormatted: data.data.map((item) => item.total),
    seriesUniqueFormatted: data.data.map((item) => item.unique),
    dateFormatted: data.data.map((item) =>
      new Date(item.date + 'T00:00:00').toLocaleString(undefined, {
        day: '2-digit',
        month: '2-digit'
      })
    )
  }
}

// export function listAccessTotalSanitizer(
//   data: ListAccessTotalApiResult
// ): ListAccessTotalFormattedServiceResult {
//   return {
//     ...data,
//     seriesDateFormatted: data.data.map((item) => item.total),
//     xaxisTotalFormatted: data.data.map((item) =>
//       new Date(item.date + 'T00:00:00').toLocaleString(undefined, {
//         day: '2-digit',
//         month: '2-digit'
//       })
//     )
//   }
// }

export function listAccessApplicationUniqueSanitizer(
  data: ListAccessApplicationApiResult
): ListAccessApplicationUniqueServiceResult {
  return {
    ...data,
    data: data.data.map((application) => ({
      ...application,
      date: new Date(application.date + 'T00:00:00').toLocaleString(undefined, {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
      })
    })),
    firstApplicationName: data.data.flatMap((application) => application.apps.firstApp.name)[0],
    secondApplicationName: data.data.map((application) => application.apps.secondApp.name)[0],
    firstSeriesUniqueFormatted: data.data.map((application) => application.apps.firstApp.unique),
    firstSeriesTotalFormatted: data.data.map((application) => application.apps.firstApp.total),
    secondSeriesTotalFormatted: data.data.map((application) => application.apps.secondApp.total),
    secondSeriesUniqueFormatted: data.data.map((application) => application.apps.secondApp.unique),
    axisDateFormatted: data.data.map((application) =>
      new Date(application.date + 'T00:00:00').toLocaleString(undefined, {
        day: '2-digit',
        month: '2-digit'
      })
    )
  }
}

export function ListSchoolAccessSanitizer(
  data: ListSchoolAccessApiResult
): ListSchoolAccessServiceResult {
  return {
    ...data,
    data: data.data.map((school) => ({
      ...school,
      date: new Date(school.date + 'T00:00:00').toLocaleString(undefined, {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
      })
    })),
    firstInstitutionName: data.data.flatMap(
      (application) => application.orgs.firstInstitution.name
    )[0],
    secondInstitutionName: data.data.map(
      (application) => application.orgs.secondInstitution.name
    )[0],
    firstSeriesUniqueFormatted: data.data.map(
      (application) => application.orgs.firstInstitution.unique
    ),
    secondSeriesUniqueFormatted: data.data.map(
      (application) => application.orgs.secondInstitution.unique
    ),
    axisDateFormatted: data.data.map((application) =>
      new Date(application.date + 'T00:00:00').toLocaleString(undefined, {
        day: '2-digit',
        month: '2-digit'
      })
    )
  }
}

export function listAccessApplicationUniqueRolesSanitizer(
  data: ListAccessApplicationUniqueRolesApiResult
): ListAccessApplicationUniqueRolesServiceResult {
  return {
    ...data,
    seriesDateFormatted: [
      {
        name: 'Estudante',
        data: data.data.map((item) => item.students)
      },
      {
        name: 'Professor',
        data: data.data.map((item) => item.teachers)
      },
      {
        name: 'Colaboradores',
        data: data.data.map((item) => item.staff)
      }
    ],
    xaxisTotalFormatted: data.data.map((item) => item.name)
  }
}

export function listChildrenSanitizer(data: ListChildrenApiResult): ListChildrenServiceResult {
  return {
    data: data.data.map((school) => ({
      codHub: school.cod_hub,
      idInstituicao: school.id_instituicao,
      instituicao: String(school.nom_instituicao)
    }))
  }
}

export function listAccessApplicationSchoolRolesSanitizer(
  data: ListAccessApplicationSchoolRolesApiResult
): ListAccessApplicationSchoolRolesServiceResult {
  return {
    ...data,
    seriesDateFormatted: data.data.map((school) => school.total),
    xaxisTotalFormatted: data.data.map((school) => school.name)
  }
}

export function listActiveApplicationsSanitizer(
  data: ListActiveApplicationsApiResult[]
): ListActiveApplicationsServiceResultFormatted {
  return {
    dataFormatted: data,
    axis: data.map((application) => application.appName),
    series: [
      {
        name: 'Estudante',
        data: data.map((application) => application.licensedStudents)
      },
      {
        name: 'Professor',
        data: data.map((application) => application.licensedTeatchers)
      }
    ]
  }
}

export function listSchoolUsersSanitizer(
  data: ListSchoolUsersApiResult[]
): ListSchoolUsersServiceResultFormatted {
  return {
    dataFormatted: data,
    axis: data.map((application) => application.orgHubName),
    series: [
      {
        name: 'Estudante',
        data: data.map((application) => application.registeredStudentsByOrg)
      },
      {
        name: 'Professor',
        data: data.map((application) => application.registeredTeachersByOrg)
      }
    ]
  }
}

export function listAppsSummarySanitizer(
  data: ListAppsSummaryApiResult[]
): ListAppsSummaryServiceResultFormatted {
  const axis: string[] = []
  const series: number[] = []

  const applications: Record<string, { name: string; data: number[]; icon: string }> = {}

  data.forEach((yearData) => {
    yearData.apps.forEach((app) => {
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (!applications[app.name]) {
        applications[app.name] = { name: app.name, data: [], icon: app.icon }
      }
      applications[app.name].data.push(app.registered)
    })
  })

  const tableYear = data.map((yearData) => yearData.year.toString())

  data.forEach((yearData) => {
    axis.push(yearData.year.toString())
    const totalRegistered = yearData.apps.reduce((sum, app) => sum + app.registered, 0)
    series.push(totalRegistered)
  })

  const application = Object.values(applications).reduce<
    Record<string, { name: string; data: number[]; icon: string }>
  >((acc, app) => {
    acc[app.name] = { name: app.name, data: app.data, icon: app.icon }
    return acc
  }, {})

  return {
    dataFormatted: data,
    tableYear,
    application,
    axis,
    series
  }
}

export function handleFilterSanitizer(data: BaseFilterAccess): BaseFilterAccess {
  return {
    ...data,
    endDate: data.endDate ? convertDateToISO(data.endDate) : undefined,
    startDate: data.endDate ? convertDateToISO(data.endDate) : undefined,
    institutionId: data.institutionId === 'all' ? undefined : data.institutionId,
    roleFilter: data.roleFilter === 'all' ? undefined : data.roleFilter,
    schoolLevel: data.schoolLevel === 'all' ? undefined : data.schoolLevel,
    classRoom: data.classRoom === 'all' ? undefined : data.classRoom,
    regionalId: data.regionalId === 'all' ? undefined : data.regionalId,
    search: data.search ? data.search : undefined
  }
}

export function listSchoolStudentsSanitizer(
  data: ListSchoolStudentsApiResult[]
): ListSchoolStudentsServiceResultFormatted {
  return {
    dataFormatted: data.map((newdata) => ({
      ...newdata,
      last_access_date: newdata.first_access_date
        ? new Date(newdata.last_access_date + 'T00:00:00').toLocaleString(undefined, {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
          })
        : '-',
      first_access_date: newdata.first_access_date
        ? new Date(newdata.first_access_date + 'T00:00:00').toLocaleString(undefined, {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
          })
        : '-'
    })),
    axis: data.map((student) => student.name),
    series: data.map((student) => student.unique_access)
  }
}
