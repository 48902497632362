import { Div } from '@positivote/design-system/components/Div'
import { Grid } from '@positivote/design-system/components/Grid'
import { Tooltip } from '@positivote/design-system/components/Tooltip'
import { Typography } from '@positivote/design-system/components/Typography'
import { CheckCircleIcon } from '@positivote/design-system/icons/CheckCircle'
import { ErrorIcon } from '@positivote/design-system/icons/Error'
import { v4 as uuidV4 } from 'uuid'

import { EmptyList } from '@/common/components/EmptyList'
import { i18n } from '@/common/i18n'
import { DefaultAcademicSyncData, StaffAcademicSync } from '@/modules/hub/academic-sync/contracts'

export interface ListDetailsStaffProps {
  data?: DefaultAcademicSyncData<StaffAcademicSync>
  filterStatus: string
}

export function ListDetailsStaff({ data, filterStatus }: ListDetailsStaffProps): JSX.Element {
  const newDataFilter =
    filterStatus === 'all'
      ? data?.data
      : data?.data.filter((status) => status.status === filterStatus)
  return (
    <Div css={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
      {!data?.data.length ? (
        <EmptyList title={i18n().modules.hub.academicSync.components.emptyListStaff} />
      ) : (
        <Div
          css={{
            display: 'flex',
            flex: 1,
            backgroundColor: '$surface-2',
            borderRadius: '$md',
            overflow: 'hidden',
            flexDirection: 'column'
          }}
        >
          <Grid
            css={{
              display: 'flex',
              padding: '$2xs $md',
              background: '$outline-variant'
            }}
          >
            <Grid xl={1} css={{ display: 'flex', alignItems: 'center', gap: '$md' }}>
              <Typography
                data-testid="Typography-header-discipline"
                variant="titleMedium"
                lineClamp={1}
                css={{ color: '$on-surface' }}
              >
                {i18n().modules.hub.academicSync.components.status}
              </Typography>
            </Grid>

            <Grid xl={1}>
              <Typography
                data-testid="Typography-header-code"
                variant="titleMedium"
                lineClamp={1}
                css={{ color: '$on-surface' }}
              >
                {i18n().modules.hub.academicSync.components.line}
              </Typography>
            </Grid>
            <Grid xl={1.5}>
              <Typography
                data-testid="Typography-header-base-discipline"
                variant="titleMedium"
                lineClamp={1}
                css={{ color: '$on-surface' }}
              >
                {i18n().modules.hub.academicSync.components.code}
              </Typography>
            </Grid>
            <Grid xl={4}>
              <Typography
                data-testid="Typography-header-base-discipline"
                variant="titleMedium"
                lineClamp={1}
                css={{ color: '$on-surface' }}
              >
                {i18n().modules.hub.academicSync.components.fullName}
              </Typography>
            </Grid>
            {/* <Grid xl={2.5}>
              <Typography
                data-testid="Typography-header-base-discipline"
                variant="titleMedium"
                lineClamp={1}
                css={{ color: '$on-surface' }}
              >
                {i18n().modules.hub.academicSync.components.birthday}
              </Typography>
            </Grid> */}

            {/* <Grid xl={1}>
              <Typography
                data-testid="Typography-header-base-discipline"
                variant="titleMedium"
                lineClamp={1}
                css={{ color: '$on-surface' }}
              >
                {i18n().modules.hub.academicSync.components.gender}
              </Typography>
            </Grid> */}
            <Grid xl={3}>
              <Typography
                data-testid="Typography-header-base-discipline"
                variant="titleMedium"
                lineClamp={1}
                css={{ color: '$on-surface' }}
              >
                {i18n().modules.hub.academicSync.components.email}
              </Typography>
            </Grid>
            <Grid xl={1.5}>
              <Typography
                data-testid="Typography-header-base-discipline"
                variant="titleMedium"
                lineClamp={1}
                css={{ color: '$on-surface' }}
              >
                {i18n().modules.hub.academicSync.components.cpf}
              </Typography>
            </Grid>
          </Grid>

          {newDataFilter?.map((student) => (
            <Grid
              key={uuidV4()}
              css={{
                display: 'flex',
                padding: '$2xs $md',
                borderWidth: '$thin',
                borderStyle: 'solid',
                borderColor: '$surface-variant',
                alignItems: 'center'
              }}
            >
              <Grid xl={1} css={{ display: 'flex', alignItems: 'center', gap: '$md' }}>
                {student.status === 'SUCCESS' && (
                  <Tooltip label={student.message} placement="top">
                    <CheckCircleIcon fill="$success" />
                  </Tooltip>
                )}
                {student.status === 'ERROR' && (
                  <Tooltip label={student.message} placement="top">
                    <ErrorIcon css={{ pointerEvents: 'unset' }} fill="$critical" />
                  </Tooltip>
                )}
              </Grid>

              <Grid xl={1}>
                <Typography
                  data-testid="Typography-header-code"
                  variant="bodySmall"
                  lineClamp={1}
                  css={{ color: '$on-surface' }}
                >
                  {student.instance.systemId}
                </Typography>
              </Grid>
              <Grid xl={1.5}>
                <Typography
                  data-testid="Typography-header-base-discipline"
                  variant="bodySmall"
                  lineClamp={1}
                  css={{ color: '$on-surface' }}
                >
                  {student.instance.code}
                </Typography>
              </Grid>
              <Grid xl={4}>
                <Typography
                  data-testid="Typography-header-base-discipline"
                  variant="bodySmall"
                  lineClamp={1}
                  css={{ color: '$on-surface' }}
                >
                  {student.instance.name}
                </Typography>
              </Grid>
              {/* <Grid xl={2.5}>
                <Typography
                  data-testid="Typography-header-base-discipline"
                  variant="bodySmall"
                  lineClamp={1}
                  css={{ color: '$on-surface' }}
                >
                  {student.instance.birthday}
                </Typography>
              </Grid> */}

              {/* <Grid xl={1}>
                <Typography
                  data-testid="Typography-header-base-discipline"
                  variant="bodySmall"
                  lineClamp={1}
                  css={{ color: '$on-surface' }}
                >
                  {student.instance.genre}
                </Typography>
              </Grid> */}
              <Grid xl={3}>
                <Tooltip label={student.instance.email}>
                  <Typography
                    data-testid="Typography-header-base-discipline"
                    variant="bodySmall"
                    lineClamp={1}
                    css={{ color: '$on-surface' }}
                  >
                    {student.instance.email}
                  </Typography>
                </Tooltip>
              </Grid>
              <Grid xl={1.5}>
                <Tooltip label={student.instance.document}>
                  <Typography
                    data-testid="Typography-header-base-discipline"
                    variant="bodySmall"
                    lineClamp={1}
                    css={{ color: '$on-surface' }}
                  >
                    {student.instance.document}
                  </Typography>
                </Tooltip>
              </Grid>
            </Grid>
          ))}
        </Div>
      )}
    </Div>
  )
}
