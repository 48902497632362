import { Main } from '@positivote/design-system/components/Main'
import { Tabs } from '@positivote/design-system/components/Tabs'
import { useTheme } from '@positivote/design-system/hooks'
import { Breakpoint } from '@positivote/design-system/theme'
import { useNavigate } from 'react-router-dom'

import { AppBar } from '@/common/components/AppBar'
import { LargerScreensOnly } from '@/common/components/LargerScreensOnly'
import { changePageTitle } from '@/common/helpers'
import { i18n } from '@/common/i18n'
import { useAuth } from '@/modules/hub/auth/contexts'

import { Access } from './Access'
import { Managerial } from './Managerial'
import { Overview } from './Overview'

export function ListReports(): JSX.Element {
  changePageTitle(i18n().modules.hub.reports.pages.list.pageTitle)
  const { breakpoint } = useTheme()
  const { profile } = useAuth()
  const navigate = useNavigate()

  const tabs = [
    {
      label: 'Visão Geral',
      key: 'personalization',
      disable: true,
      children: breakpoint <= Breakpoint.md ? <LargerScreensOnly /> : <Overview />,
      breakpoint: Breakpoint.lg,
      roles: ['administrador', 'instituicao', 'diretor', 'coordenador', 'professor']
    },
    {
      label: 'Acessos',
      key: 'access',
      disable: true,
      children: breakpoint <= Breakpoint.md ? <LargerScreensOnly /> : <Access />,
      breakpoint: Breakpoint.lg,
      roles: ['administrador', 'instituicao', 'diretor', 'coordenador', 'professor']
    },
    {
      label: 'Gerencial',
      key: 'managerial',
      disable: true,
      children: breakpoint <= Breakpoint.md ? <LargerScreensOnly /> : <Managerial />,
      breakpoint: Breakpoint.lg,
      roles: ['administrador', 'instituicao', 'diretor']
    }
  ]

  const filterTabs = tabs.filter((currentTabs) => {
    return profile && currentTabs.roles.includes(profile.role.code)
  })

  return (
    <Main css={{ display: 'flex', flexDirection: 'column', flex: 1, overflowX: 'hidden' }}>
      <AppBar title="Relatórios" goBackFunction={() => navigate(-1)} />
      <Tabs options={filterTabs} />
    </Main>
  )
}
