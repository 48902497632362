import {
  GenderDictionaryValue,
  cellMask,
  cpfMask,
  formatPtBrDateToIsoString,
  genderDictionary,
  parseValidDateOrDefault,
  removeMask,
  validateBase64OrUrl
} from '@/common/helpers'
import {
  ListAccountProfileApiResult,
  Profile,
  ProfileDetails,
  ProfileDetailsFormatted,
  ProfileMeApi,
  ShortProfile,
  UpdateProfileApiParams
} from '@/modules/hub/profiles/contracts'

import { UpdateProfileForm } from './contracts/form'

export function fromApiMeProfileSanitizer(profile: ProfileMeApi): ProfileDetailsFormatted {
  const genderFound = genderDictionary[profile.genre] as GenderDictionaryValue | undefined
  return {
    id: profile.id,
    name: profile.name,
    email: profile.email,
    code: profile.code,
    role: {
      code: profile.role.code,
      name: profile.role.name
    },
    organizationId: profile.institution.id,
    login: profile.login,
    cpf: profile.document,
    isDpoUser: profile.is_dpo !== '0',
    cpfFormatted: profile.document ? cpfMask(profile.document) : null,
    gender: genderFound?.key ?? genderDictionary.O.key,
    genderFormatted: genderFound?.value ?? genderDictionary.O.value,
    birthdayFormatted: parseValidDateOrDefault(profile.birthday),
    birthday: profile.birthday,
    phone: profile.phone,
    phoneFormatted: cellMask(profile.phone),
    picture: profile.dsc_url_picture,
    organization: {
      code: profile.institution.code,
      name: profile.institution.name,
      address: profile.institution.address,
      kindId: profile.institution.kind.id
    }
  }
}

export function profileDetailsFormatSanitizer(profile: ProfileDetails): ProfileDetailsFormatted {
  const genderFound = genderDictionary[profile.gender] as GenderDictionaryValue | undefined
  return {
    id: profile.id,
    name: profile.name,
    email: profile.email,
    code: profile.code,
    role: profile.role,
    organizationId: profile.organizationId,
    login: profile.login,
    cpf: profile.cpf,
    isDpoUser: profile.isDpoUser,
    cpfFormatted: profile.cpf ? cpfMask(profile.cpf) : null,
    gender: genderFound?.key ?? genderDictionary.O.key,
    genderFormatted: genderFound?.value ?? genderDictionary.O.value,
    birthdayFormatted: new Date(profile.birthday).toLocaleString(undefined, {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    }),
    birthday: profile.birthday,
    phone: profile.phone,
    phoneFormatted: profile.phone ? cellMask(profile.phone) : null,
    picture: profile.picture,
    organization: {
      code: profile.organization.code,
      name: profile.organization.name,
      address: profile.organization.address,
      kindId: profile.organization.kindId
    }
  }
}

export function updateProfileSchemaSanitizer(params: UpdateProfileForm): UpdateProfileForm {
  return {
    name: (params.name || '').trim(),
    birthday: params.birthday ? formatPtBrDateToIsoString(params.birthday) : undefined,
    gender: params.gender,
    cpf: params.cpf ? removeMask(params.cpf) : undefined,
    phone: params.phone ? removeMask(params.phone) : undefined,
    picture: params.picture ?? '',
    isDpoUser: params.isDpoUser
  }
}

export function updateProfileApiParamsSanitizer(params: Profile): UpdateProfileApiParams {
  return {
    cpf: params.cpf,
    phone: params.phone,
    birthday: params.birthday,
    name: params.name,
    email: params.email,
    code: params.code,
    role: params.role.code,
    login: params.login,
    gender: params.gender,
    picture: params.picture,
    is_dpo_user: params.isDpoUser ? 1 : 0
  }
}

export function profileToFormSanitizer(params: ProfileDetailsFormatted): UpdateProfileForm {
  return {
    name: params.name,
    gender: params.gender,
    cpf: params.cpf ? cpfMask(params.cpf) : undefined,
    phone: params.phone ? cellMask(params.phone) : undefined,
    birthday: parseValidDateOrDefault(params.birthday),
    picture: validateBase64OrUrl(params.picture ?? '')
  }
}

export function fromAccountProfileApiSanitizer(
  profiles: ListAccountProfileApiResult['data']
): ShortProfile[] {
  return profiles.map((profile) => ({
    ...profile,
    allowedImageUseOnEdtech: profile.allowed_image_use_on_edtech,
    birthday: parseValidDateOrDefault(profile.birthday),
    organization: {
      id: profile.institution_id,
      code: profile.institution_code,
      name: profile.institution_name,
      address: profile.institution_address
    },
    role: {
      id: profile.role_id,
      code: profile.role_code,
      name: profile.role_name
    },
    userId: profile.user_id
  }))
}
