import { yupResolver } from '@hookform/resolvers/yup'
import { Button } from '@positivote/design-system/components/Button'
import { Div } from '@positivote/design-system/components/Div'
import { Divider } from '@positivote/design-system/components/Divider'
import { Grid } from '@positivote/design-system/components/Grid'
import { Typography } from '@positivote/design-system/components/Typography'
import { FormAutocomplete } from '@positivote/design-system/components-form/Autocomplete'
import { FormContainer } from '@positivote/design-system/components-form/Container'
import { FormDatePicker } from '@positivote/design-system/components-form/DatePicker'
import { FormSelect } from '@positivote/design-system/components-form/Select'
import { CheckIcon } from '@positivote/design-system/icons/Check'
import { FilterListIcon } from '@positivote/design-system/icons/FilterList'
import { SearchIcon } from '@positivote/design-system/icons/Search'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'

import { useAuth } from '@/modules/hub/auth/contexts'
import { allClassLevelsWithFinalValue } from '@/modules/hub/classroom/constants'
import { useListClassroom } from '@/modules/hub/classroom/hooks'
import { OrganizationKind } from '@/modules/hub/organizations/contracts'
import { BaseFilterAccess } from '@/modules/hub/reports/contracts/form'
import { defaultRoles } from '@/modules/hub/reports/contracts/model'
import { useListChildren } from '@/modules/hub/reports/hooks'
import { handleFilterSanitizer } from '@/modules/hub/reports/sanitizers'
import { filterFormeSchema } from '@/modules/hub/reports/validations'
import { useListShortStudent } from '@/modules/hub/users/hooks'

export interface BaseFilterProps {
  onFilter: (data: BaseFilterAccess) => void
  isLoading: boolean
}

export function BaseFilter({ onFilter, isLoading }: BaseFilterProps): JSX.Element {
  const { profile } = useAuth()
  const isSchool = profile?.organization.kindId === OrganizationKind.SCHOOL
  const isGroup = profile?.organization.kindId === OrganizationKind.GROUP
  const { control, handleSubmit, formState, watch, setValue } = useForm<BaseFilterAccess>({
    mode: 'onSubmit',
    resolver: yupResolver(filterFormeSchema),
    defaultValues: {
      roleFilter: 'all',
      schoolLevel: 'all',
      startDate: undefined,
      endDate: undefined,
      institutionId: 'all',
      classRoom: 'all',
      regionalId: 'all'
    }
  })

  const classRoom = watch('classRoom')
  const institutionId = watch('institutionId')
  const regionalId = watch('regionalId')

  const roleFilter = watch('roleFilter')
  const startDate = watch('startDate')
  const endDate = watch('endDate')
  const schoolLevel = watch('schoolLevel')

  const isDirty =
    roleFilter !== 'all' ||
    schoolLevel !== 'all' ||
    classRoom !== 'all' ||
    regionalId !== 'all' ||
    startDate !== undefined ||
    endDate !== undefined

  console.log(institutionId)

  function handleFilter(data: BaseFilterAccess): void {
    const newDate = {
      ...data,
      institutionId: data.institutionId === 'all' ? profile?.organization.code : data.institutionId
    }
    onFilter(handleFilterSanitizer(newDate))
  }

  const listChildren = useListChildren({
    model: {
      institutionId:
        regionalId && regionalId !== 'all'
          ? regionalId
          : (profile?.organization.code as unknown as string)
    },
    queryOptions: {
      enabled: !isSchool
    }
  })

  const listRegional = useListChildren({
    model: {
      institutionId: profile?.organization.code as unknown as string
    },
    queryOptions: {
      enabled: profile?.organization.kindId === OrganizationKind.GROUP
    }
  })

  const listChildrenFormatted = [
    { codHub: 'all', instituicao: 'Todas as escolas' },
    ...(listChildren.data?.data ?? [])
  ]

  const listRegionalFormatted = [
    { codHub: 'all', instituicao: 'Todas as regionais' },
    ...(listRegional.data?.data ?? [])
  ]

  const allClassLevelsWithFinalValueFormatted = [
    { key: 'all', value: 'Todos os anos escolares' },
    ...allClassLevelsWithFinalValue
  ]

  const listClassroom = useListClassroom({
    model: {
      institutionCode:
        institutionId && institutionId !== 'all'
          ? institutionId
          : (profile?.organization.code as unknown as string)
    },
    queryOptions: {
      enabled: institutionId !== 'all'
    }
  })

  const listStudent = useListShortStudent({
    model: {
      classroomId: classRoom !== 'all' ? Number(classRoom) : undefined,
      institutionCode: institutionId
    },
    queryOptions: {
      enabled: institutionId !== 'all' && classRoom !== 'all'
    }
  })

  const listClassroomFormatted = [
    { id: 'all', name: 'Todas as turmas' },
    ...(listClassroom.data?.registers ?? [])
  ]

  function handleClearFilter(): void {
    setValue('classRoom', 'all')
    if (!isSchool) {
      setValue('institutionId', 'all')
    }
    setValue('endDate', undefined)
    setValue('startDate', undefined)
    setValue('schoolLevel', 'all')
    setValue('roleFilter', 'all')
    setValue('search', undefined)

    const data: BaseFilterAccess = {
      classRoom: undefined,
      startDate: undefined,
      endDate: undefined,
      institutionId: profile?.organization.code,
      roleFilter: undefined,
      schoolLevel: undefined,
      search: undefined
    }
    onFilter(data)
  }

  useEffect(() => {
    if (isSchool) {
      setValue('institutionId', profile.organization.code)
    }
  }, [isSchool, profile])

  return (
    <FormContainer formHandleSubmit={handleSubmit} onSubmit={handleFilter}>
      <Div css={{ display: 'flex', flexDirection: 'column', gap: '$md', marginBottom: '$md' }}>
        <Div css={{ padding: '$md', backgroundColor: '$surface-variant', borderRadius: '$lg' }}>
          <Typography variant="bodyLarge">
            Para visualizar o relatório de uma turma ou estudante, digite o nome como cadastrado no
            sistema.
          </Typography>
        </Div>
        <Divider />
      </Div>

      <Div css={{ display: 'flex', alignItems: 'center', gap: '$2xs', marginBottom: '$md' }}>
        <FilterListIcon size={18} />
        <Typography
          data-testid="Typography-titleFilter"
          variant="titleMedium"
          css={{ color: '$on-surface' }}
        >
          Opções de filtragem
        </Typography>
      </Div>

      <Grid spacing="$lg" css={{ width: 'unset', flex: 1 }}>
        <Grid xl={profile?.role.code === 'professor' ? 6 : 4}>
          <FormDatePicker
            name="startDate"
            control={control}
            label="Data de ínicio"
            variant="outlined"
            errorText={formState.errors.startDate?.message}
          />
        </Grid>
        <Grid xl={profile?.role.code === 'professor' ? 6 : 4}>
          <FormDatePicker
            name="endDate"
            control={control}
            label="Data de término"
            variant="outlined"
            errorText={formState.errors.endDate?.message}
          />
        </Grid>
        {profile?.role.code && ['diretor', 'administrador'].includes(profile.role.code) && (
          <Grid xl={4}>
            <FormSelect
              name="roleFilter"
              control={control}
              label="Selecione o tipo de usuário"
              variant="outlined"
              optionKeyField="key"
              optionTitleField="name"
              options={defaultRoles}
            />
          </Grid>
        )}

        {isGroup && (
          <Grid xl={6}>
            <FormSelect
              name="regionalId"
              control={control}
              label="Selecionar regional"
              variant="outlined"
              optionKeyField="codHub"
              optionTitleField="instituicao"
              options={listRegionalFormatted}
            />
          </Grid>
        )}

        {!isSchool && (
          <Grid xl={isGroup ? 6 : 12}>
            <FormSelect
              disabled={isGroup && regionalId === 'all'}
              name="institutionId"
              control={control}
              label="Selecionar instituição"
              variant="outlined"
              optionKeyField="codHub"
              optionTitleField="instituicao"
              options={listChildrenFormatted}
            />
          </Grid>
        )}

        <Grid xl={isSchool ? 4 : 4}>
          <FormSelect
            name="schoolLevel"
            control={control}
            label="Selecionar o ano escolar"
            variant="outlined"
            optionKeyField="key"
            optionTitleField="value"
            options={allClassLevelsWithFinalValueFormatted}
          />
        </Grid>
        <Grid xl={4}>
          <FormAutocomplete
            isLoading={listClassroom.isFetching}
            disabled={!isSchool && institutionId === 'all'}
            label="Selecionar turma"
            variant="outlined"
            control={control}
            name="classRoom"
            optionKeyField="id"
            optionTitleField="name"
            options={listClassroomFormatted}
          />
        </Grid>
        <Grid xl={isSchool ? 4 : 4}>
          <FormAutocomplete
            disabled={institutionId === 'all' || classRoom === 'all'}
            isLoading={listStudent.isFetching}
            name="search"
            control={control}
            data-testid="searchBy"
            label="Pesquisar por estudante"
            optionKeyField="name"
            optionTitleField="name"
            options={listStudent.data?.registers ?? []}
            variant="outlined"
            leadingIcon={{ icon: SearchIcon }}
          />
        </Grid>
        <Grid xl={12} css={{ display: 'flex', justifyContent: 'end', gap: '$lg' }}>
          <Button
            type="submit"
            LeadingIcon={<FilterListIcon />}
            variant="outlined"
            onClick={handleClearFilter}
            disabled={!isDirty}
          >
            Limpar filtros
          </Button>

          <Button isLoading={isLoading} type="submit" LeadingIcon={<CheckIcon />} variant="tonal">
            Aplicar filtros
          </Button>
        </Grid>
      </Grid>
    </FormContainer>
  )
}
