import { BaseCard } from '@positivote/design-system/components/BaseCard'
import { Button } from '@positivote/design-system/components/Button'
import { Chip } from '@positivote/design-system/components/Chip'
import { Div } from '@positivote/design-system/components/Div'
import { Grid } from '@positivote/design-system/components/Grid'
import { IconWrapper } from '@positivote/design-system/components/IconWrapper'
import { Image } from '@positivote/design-system/components/Image'
import { Main } from '@positivote/design-system/components/Main'
import { Typography } from '@positivote/design-system/components/Typography'
import { useTheme } from '@positivote/design-system/hooks'
import { CancelIcon } from '@positivote/design-system/icons/Cancel'
import { EditIcon } from '@positivote/design-system/icons/Edit'
import { KeyIcon } from '@positivote/design-system/icons/Key'
import { PersonIcon } from '@positivote/design-system/icons/Person'
import { RefreshIcon } from '@positivote/design-system/icons/Refresh'
import { Breakpoint } from '@positivote/design-system/theme'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { AppBar } from '@/common/components/AppBar'
import { TextDialog } from '@/common/components/TextDialog'
import { WrappedLoader } from '@/common/components/WrappedLoader'
import { changePageTitle } from '@/common/helpers'
import { i18n } from '@/common/i18n'
import { Footer } from '@/common/layouts/Footer'
import { LockIcon } from '@/fixme/icons/Lock'
import { useAuth } from '@/modules/hub/auth/contexts'
import { useNewPassword, useShowUser } from '@/modules/hub/users/hooks'

export function UserDetails(): JSX.Element {
  const navigate = useNavigate()
  const newPassword = useNewPassword()
  const { breakpoint } = useTheme()
  const { profile } = useAuth()
  const params = useParams()
  const [isOpenDialog, setIsOpenDialog] = useState(false)
  const [password, setNewPassword] = useState('')

  const showUser = useShowUser({
    model: {
      userId: Number(params.id)
    },
    queryOptions: {
      enabled: !!params.id
    },
    onError: () => {
      navigate('/data-management/my-users')
    }
  })
  changePageTitle(showUser.data?.fullNameFormatted ?? '')

  function handleNewPassword(): void {
    newPassword.mutate({
      model: {
        codPerfil: showUser.data!.code,
        idInstituicao: String(profile!.organizationId)
      },
      onError: () => {
        setIsOpenDialog(false)
      },
      onSuccess: (data) => {
        setNewPassword(data.newPassword)
        setIsOpenDialog(false)
      }
    })
  }

  return (
    <Main css={{ display: 'flex', flexDirection: 'column', flex: 1, overflowX: 'hidden' }}>
      <TextDialog
        align="center"
        data-testid="removeDiscipline"
        isOpen={isOpenDialog}
        title={{
          label: 'Redefinir senha'
        }}
        contentTexts={['Tem certeza que deseja redefinir a senha deste usuário?']}
        onCancel={() => setIsOpenDialog(false)}
        refuseAction={{
          icon: <CancelIcon size={18} />,
          label: i18n().modules.billing.serviceMapping.pages.list.removeDialog.refuse,
          handle: () => setIsOpenDialog(false)
        }}
        acceptAction={{
          icon: <KeyIcon size={18} />,
          label: 'Redefinir',
          handle: () => handleNewPassword()
        }}
        isLoading={newPassword.isPending}
        css={{
          maxWidth: 600,
          '& .Dialog-Content': {
            gap: '$none'
          }
        }}
      />
      <AppBar
        title={i18n().modules.hub.users.pages.list.appBar.title}
        goBackFunction={() => navigate(-1)}
        breadcrumbItems={[
          {
            label: i18n().modules.hub.users.pages.details.appBar.breadcrumbs.overview,
            onClick: () => {
              navigate(-2)
            }
          },
          {
            label: i18n().modules.hub.users.pages.details.appBar.breadcrumbs.myUsers,
            onClick: () => {
              navigate(-1)
            }
          },
          {
            label: showUser.data ? `${showUser.data.firstName} ${showUser.data.lastName}` : '...'
          }
        ]}
      />
      <Div css={{ display: 'flex', flexDirection: 'column', flex: 1, overflowY: 'auto' }}>
        <Div css={{ display: 'flex', flex: 1, padding: '$lg', '@sm': { padding: '$md' } }}>
          {!showUser.data ? (
            <WrappedLoader />
          ) : (
            <Grid spacing="$lg">
              <Grid xl={5} md={6} sm={12}>
                <BaseCard css={{ '& .BaseCard-StateLayer': { padding: '$lg', gap: '$lg' } }}>
                  <Div
                    css={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                  >
                    <Typography variant="titleLarge" css={{ color: '$on-surface' }}>
                      {i18n().modules.hub.users.pages.details.userProfile}
                    </Typography>
                    <Button
                      onClick={() => navigate(`/data-management/my-users/form/${showUser.data.id}`)}
                      LeadingIcon={<EditIcon size={18} />}
                      variant="filled"
                      data-testid="edit"
                    >
                      {i18n().modules.hub.users.pages.details.edit}
                    </Button>
                  </Div>
                  <Div
                    css={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      gap: '$md',
                      flexDirection: 'column'
                    }}
                  >
                    {showUser.data.picture ? (
                      <Image
                        alt={i18n().modules.hub.profiles.pages.details.pictureAlt}
                        FallbackImage={() => (
                          <IconWrapper size={160} css={{ backgroundColor: '$primary-container' }}>
                            <PersonIcon size={96} fill="$on-primary-container" />
                          </IconWrapper>
                        )}
                        css={{
                          borderRadius: '$full',
                          overflow: 'hidden',
                          objectFit: 'cover',
                          width: '160px',
                          height: '160px'
                        }}
                        src={showUser.data.picture}
                      />
                    ) : (
                      <IconWrapper size={160} css={{ backgroundColor: '$primary-container' }}>
                        <PersonIcon size={96} fill="$on-primary-container" />
                      </IconWrapper>
                    )}

                    <Typography variant="titleLarge" css={{ color: '$on-surface' }}>
                      {showUser.data.fullNameFormatted}
                    </Typography>
                  </Div>
                  <Grid spacing="$md">
                    <Grid xl={12}>
                      <Typography
                        variant="titleSmall"
                        css={{ marginBottom: '$4xs', color: '$on-surface-variant' }}
                      >
                        {i18n().modules.hub.users.pages.details.profiles}
                      </Typography>
                      <Div css={{ display: 'flex', gap: '$sm' }}>
                        {showUser.data.roles.map((role) => (
                          <Chip
                            key={role.idRole}
                            variant="outlined"
                            label={role.name}
                            color="neutral"
                          />
                        ))}
                      </Div>
                    </Grid>
                    <Grid xs={6} sm={6} md={6} xl={6}>
                      <Typography
                        variant="titleSmall"
                        css={{ marginBottom: '$4xs', color: '$on-surface-variant' }}
                      >
                        {i18n().modules.hub.users.pages.details.code}
                      </Typography>
                      <Typography variant="bodyLarge" css={{ color: '$on-surface' }}>
                        {showUser.data.code}
                      </Typography>
                    </Grid>
                    {/* <Grid xs={12} sm={6} md={6} xl={6}>
                      <Typography
                        variant="titleSmall"
                        css={{ marginBottom: '$4xs', color: '$on-surface-variant' }}
                      >
                        {i18n().modules.hub.users.pages.details.birthday}
                      </Typography>
                      <Typography variant="bodyLarge" css={{ color: '$on-surface' }}>
                        {showUser.data.birthday}
                      </Typography>
                    </Grid> */}
                    {/* {showUser.data.gender && (
                      <Grid xs={12} sm={6} md={6} xl={6}>
                        <Typography variant="titleSmall" css={{ color: '$on-surface-variant' }}>
                          {i18n().modules.hub.users.pages.details.gender}
                        </Typography>
                        <Typography variant="bodyLarge" css={{ color: '$on-surface' }}>
                          {showUser.data.genderFormatted}
                        </Typography>
                      </Grid>
                    )} */}

                    {showUser.data.document && (
                      <Grid xs={12} sm={6} md={6} xl={6}>
                        <Typography
                          variant="titleSmall"
                          css={{ marginBottom: '$4xs', color: '$on-surface-variant' }}
                        >
                          {i18n().modules.hub.users.pages.details.cpf}
                        </Typography>
                        <Typography variant="bodyLarge" css={{ color: '$on-surface' }}>
                          {showUser.data.documentFormatted}
                        </Typography>
                      </Grid>
                    )}

                    {showUser.data.phone && (
                      <Grid xs={6} sm={6} md={6} xl={6}>
                        <Typography
                          variant="titleSmall"
                          css={{ marginBottom: '$4xs', color: '$on-surface-variant' }}
                        >
                          {i18n().modules.hub.users.pages.details.phone}
                        </Typography>
                        <Typography variant="bodyLarge" css={{ color: '$on-surface' }}>
                          {showUser.data.phoneFormatted}
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                </BaseCard>
              </Grid>
              <Grid
                md={6}
                sm={12}
                xs={12}
                xl={5}
                lg={5}
                css={{ display: 'flex', flexDirection: 'column', gap: '$lg' }}
              >
                <BaseCard css={{ '& .BaseCard-StateLayer': { padding: '$lg', gap: '$lg' } }}>
                  <Typography variant="titleLarge" css={{ color: '$on-surface' }}>
                    {i18n().modules.hub.users.pages.details.infoAccess}
                  </Typography>
                  <Div css={{ gap: '$4xs' }}>
                    <Typography
                      variant="titleSmall"
                      css={{ marginBottom: '$4xs', color: '$on-surface-variant' }}
                    >
                      {i18n().modules.hub.users.pages.details.login}
                    </Typography>
                    <Typography variant="bodyLarge" css={{ color: '$on-surface' }}>
                      {showUser.data.login}
                    </Typography>
                  </Div>
                  {showUser.data.email && (
                    <Div css={{ gap: '$4xs' }}>
                      <Typography
                        variant="titleSmall"
                        css={{ marginBottom: '$4xs', color: '$on-surface-variant' }}
                      >
                        {i18n().modules.hub.users.pages.details.email}
                      </Typography>
                      <Typography variant="bodyLarge" css={{ color: '$on-surface' }}>
                        {showUser.data.email}
                      </Typography>
                    </Div>
                  )}
                  {password && (
                    <Div css={{ display: 'flex', flexDirection: 'column', gap: '$sm' }}>
                      <Div>
                        <Typography variant="bodyLarge">
                          {i18n().modules.hub.users.pages.details.titleCard}
                        </Typography>
                        <Typography variant="titleMedium">
                          {i18n().modules.hub.users.pages.details.subTitleCard}
                        </Typography>
                      </Div>

                      <Div css={{ display: 'flex', gap: '$md' }}>
                        <Div
                          css={{
                            display: 'flex',
                            padding: '$sm',
                            background: '$primary-container',
                            borderRadius: '$md',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '$2xs'
                          }}
                        >
                          <Div>
                            <KeyIcon size={24} fill="$on-primary-container" />
                          </Div>
                          <Typography
                            variant="titleMedium"
                            css={{ color: '$on-primary-container' }}
                          >
                            {password}
                          </Typography>
                        </Div>
                        <Button
                          onClick={() => setIsOpenDialog(true)}
                          isLoading={newPassword.isPending}
                          LeadingIcon={<RefreshIcon />}
                          variant="outlined"
                        >
                          {i18n().modules.hub.users.pages.details.resetNewPassword}
                        </Button>
                      </Div>
                    </Div>
                  )}
                  {!password && (
                    <Grid xl={6}>
                      <Button onClick={() => setIsOpenDialog(true)} LeadingIcon={<LockIcon />}>
                        {i18n().modules.hub.users.pages.details.resetPassword}
                      </Button>
                    </Grid>
                  )}
                </BaseCard>
                {showUser.data.roles.some((role) => role.code === 'teacher') &&
                  (!!showUser.data.enrollments.length || !!showUser.data.students.length) && (
                    <BaseCard css={{ '& .BaseCard-StateLayer': { padding: '$lg', gap: '$lg' } }}>
                      <Typography variant="titleLarge" css={{ color: '$on-surface' }}>
                        {i18n().modules.hub.users.pages.details.links}
                      </Typography>
                      <Div
                        css={{
                          display: 'flex',
                          borderWidth: '$thin',
                          borderStyle: 'solid',
                          borderRadius: '$md',
                          borderColor: '$outline-variant',
                          padding: '$sm',
                          flexDirection: 'column',
                          gap: '$sm'
                        }}
                      >
                        {showUser.data.enrollments.map((enroll) => (
                          <Div
                            key={enroll.id}
                            css={{
                              background: 'White',
                              padding: '$2xs',
                              borderRadius: '$sm',
                              display: 'flex'
                            }}
                          >
                            <Grid
                              xl={6}
                              css={{ display: 'flex', flexDirection: 'column', gap: '$sm' }}
                            >
                              <Typography variant="labelSmall">
                                {i18n().modules.hub.users.pages.details.schoolClasses}
                              </Typography>
                              <Chip color="neutral" variant="outlined" label={enroll.name} />
                            </Grid>
                            <Grid
                              xl={6}
                              css={{ display: 'flex', gap: '$sm', flexDirection: 'column' }}
                            >
                              <Typography variant="labelSmall">
                                {i18n().modules.hub.users.pages.details.discipline}
                              </Typography>
                              <Div css={{ display: 'flex', flexWrap: 'wrap', gap: '$2xs' }}>
                                {enroll.courses?.map((courses) => (
                                  <Chip
                                    color="neutral"
                                    variant="filled"
                                    key={courses.id}
                                    label={courses.name}
                                  />
                                ))}
                              </Div>
                            </Grid>
                          </Div>
                        ))}
                        {!!showUser.data.students.length && (
                          <Div
                            css={{
                              background: 'White',
                              padding: '$2xs',
                              borderRadius: '$sm',
                              display: 'flex',
                              flexDirection: 'column',
                              gap: '$2xs'
                            }}
                          >
                            <Div css={{ display: 'flex' }}>
                              <Div css={{ display: 'flex', flex: 1 }}>
                                <Typography variant="labelSmall">
                                  {i18n().modules.hub.users.pages.details.student}
                                </Typography>
                              </Div>
                              <Div css={{ display: 'flex', flex: 1 }}>
                                <Typography variant="labelSmall">
                                  {i18n().modules.hub.users.pages.details.code}
                                </Typography>
                              </Div>
                            </Div>
                            <Div css={{ display: 'flex', flexDirection: 'column', gap: '$2xs' }}>
                              {showUser.data.students.map((student) => (
                                <Div key={student.id} css={{ display: 'flex', flexWrap: 'wrap' }}>
                                  <Grid
                                    xl={6}
                                    css={{ display: 'flex', flexDirection: 'column', gap: '$sm' }}
                                  >
                                    <Chip color="neutral" variant="outlined" label={student.name} />
                                  </Grid>
                                  <Grid
                                    xl={6}
                                    css={{ display: 'flex', gap: '$sm', flexDirection: 'column' }}
                                  >
                                    <Div css={{ display: 'flex', flexWrap: 'wrap', gap: '$2xs' }}>
                                      <Chip color="neutral" variant="filled" label={student.code} />
                                    </Div>
                                  </Grid>
                                </Div>
                              ))}
                            </Div>
                          </Div>
                        )}
                      </Div>
                    </BaseCard>
                  )}
                {showUser.data.roles.some((role) => role.code === 'student') &&
                  (!!showUser.data.guardians.length || !!showUser.data.enrollments.length) && (
                    <>
                      <BaseCard css={{ '& .BaseCard-StateLayer': { padding: '$lg', gap: '$lg' } }}>
                        <Typography variant="titleLarge" css={{ color: '$on-surface' }}>
                          {i18n().modules.hub.users.pages.details.links}
                        </Typography>
                        <Div
                          css={{
                            display: 'flex',
                            borderWidth: '$thin',
                            borderStyle: 'solid',
                            borderRadius: '$md',
                            borderColor: '$outline-variant',
                            padding: '$sm',
                            flexDirection: 'column',
                            gap: '$sm'
                          }}
                        >
                          {!!showUser.data.guardians.length && (
                            <Div
                              css={{
                                background: 'White',
                                padding: '$2xs',
                                borderRadius: '$sm',
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '$2xs'
                              }}
                            >
                              <Div css={{ display: 'flex' }}>
                                <Div css={{ display: 'flex', flex: 1 }}>
                                  <Typography variant="labelSmall">
                                    {i18n().modules.hub.users.pages.details.responsible}
                                  </Typography>
                                </Div>
                                <Div css={{ display: 'flex', flex: 1 }}>
                                  <Typography variant="labelSmall">
                                    {i18n().modules.hub.users.pages.details.code}
                                  </Typography>
                                </Div>
                              </Div>
                              <Div css={{ display: 'flex', flexDirection: 'column', gap: '$2xs' }}>
                                {showUser.data.guardians.map((student) => (
                                  <Div key={student.id} css={{ display: 'flex', flexWrap: 'wrap' }}>
                                    <Grid
                                      xl={6}
                                      css={{ display: 'flex', flexDirection: 'column', gap: '$sm' }}
                                    >
                                      <Chip
                                        color="neutral"
                                        variant="outlined"
                                        label={student.name}
                                      />
                                    </Grid>
                                    <Grid
                                      xl={6}
                                      css={{ display: 'flex', gap: '$sm', flexDirection: 'column' }}
                                    >
                                      <Div css={{ display: 'flex', flexWrap: 'wrap', gap: '$2xs' }}>
                                        <Chip
                                          color="neutral"
                                          variant="filled"
                                          label={student.code}
                                        />
                                      </Div>
                                    </Grid>
                                  </Div>
                                ))}
                              </Div>
                            </Div>
                          )}

                          {!!showUser.data.enrollments.length && (
                            <Div
                              css={{
                                background: 'White',
                                padding: '$2xs',
                                borderRadius: '$sm',
                                display: 'flex'
                              }}
                            >
                              <Grid
                                xl={6}
                                css={{ display: 'flex', flexDirection: 'column', gap: '$sm' }}
                              >
                                <Typography variant="labelSmall">
                                  {i18n().modules.hub.users.pages.details.schoolClasses}
                                </Typography>
                                <Div css={{ display: 'flex', gap: '$sm' }}>
                                  {showUser.data.enrollments.map((enroll) => (
                                    <Chip
                                      key={enroll.id}
                                      color="neutral"
                                      variant="outlined"
                                      label={enroll.name}
                                    />
                                  ))}
                                </Div>
                              </Grid>
                            </Div>
                          )}
                        </Div>
                      </BaseCard>
                    </>
                  )}
                {showUser.data.roles.some((role) => role.code === 'responsible') &&
                  !showUser.data.roles.some((role) => role.code === 'teacher') &&
                  !!showUser.data.students.length && (
                    <BaseCard css={{ '& .BaseCard-StateLayer': { padding: '$lg', gap: '$lg' } }}>
                      <Typography variant="titleLarge" css={{ color: '$on-surface' }}>
                        {i18n().modules.hub.users.pages.details.links}
                      </Typography>
                      <Div
                        css={{
                          display: 'flex',
                          borderWidth: '$thin',
                          borderStyle: 'solid',
                          borderRadius: '$md',
                          borderColor: '$outline-variant',
                          padding: '$sm',
                          flexDirection: 'column',
                          gap: '$sm'
                        }}
                      >
                        <Div
                          css={{
                            background: 'White',
                            padding: '$2xs',
                            borderRadius: '$sm',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '$2xs'
                          }}
                        >
                          <Div css={{ display: 'flex' }}>
                            <Div css={{ display: 'flex', flex: 1 }}>
                              <Typography variant="labelSmall">
                                {i18n().modules.hub.users.pages.details.student}
                              </Typography>
                            </Div>
                            <Div css={{ display: 'flex', flex: 1 }}>
                              <Typography variant="labelSmall">
                                {i18n().modules.hub.users.pages.details.code}
                              </Typography>
                            </Div>
                          </Div>
                          <Div css={{ display: 'flex', flexDirection: 'column', gap: '$2xs' }}>
                            {showUser.data.students.map((student) => (
                              <Div key={student.id} css={{ display: 'flex', flexWrap: 'wrap' }}>
                                <Grid
                                  xl={6}
                                  css={{ display: 'flex', flexDirection: 'column', gap: '$sm' }}
                                >
                                  <Chip color="neutral" variant="outlined" label={student.name} />
                                </Grid>
                                <Grid
                                  xl={6}
                                  css={{ display: 'flex', gap: '$sm', flexDirection: 'column' }}
                                >
                                  <Div css={{ display: 'flex', flexWrap: 'wrap', gap: '$2xs' }}>
                                    <Chip color="neutral" variant="filled" label={student.code} />
                                  </Div>
                                </Grid>
                              </Div>
                            ))}
                          </Div>
                        </Div>
                      </Div>
                    </BaseCard>
                  )}
              </Grid>
            </Grid>
          )}
        </Div>
        {breakpoint === Breakpoint.xs && <Footer />}
      </Div>
    </Main>
  )
}
