import { ApexOptions } from 'apexcharts'

export const defaultLineGraphics: {
  series: Array<{ name: string; data: number[] }>
  options: ApexOptions
} = {
  series: [
    {
      name: '',
      data: []
    }
  ],
  options: {
    chart: {
      type: 'line',
      zoom: {
        enabled: false
      },
      toolbar: {
        tools: {
          download: false
        }
      }
    },
    dataLabels: {
      enabled: false
    },
    markers: {
      size: 0
    },
    stroke: {
      curve: 'straight'
    },
    title: {
      text: '',
      align: 'left',
      style: {
        fontSize: '16px',
        fontWeight: 500,
        fontFamily: 'Roboto',
        color: '#17191C'
      }
    },
    // subtitle: {
    //   text: 'Analysis of desktop sales over the year', // Subtítulo
    //   align: 'left',
    //   style: {
    //     fontSize: '14px',
    //     color: '#666'
    //   }
    // },
    grid: {
      row: {
        colors: ['#f3f3f3', 'transparent'],
        opacity: 0.5
      }
    },
    yaxis: {
      title: {
        style: {
          fontSize: '10px',
          fontWeight: 500,
          fontFamily: 'Roboto',
          color: '#5D6675'
        }
      },
      labels: {
        style: {
          fontSize: '10px',
          fontWeight: 500,
          fontFamily: 'Roboto',
          colors: '#5D6675'
        }
      }
    },
    xaxis: {
      tickAmount: 4,
      labels: {
        rotate: 0,
        style: {
          fontSize: '10px',
          fontWeight: 500,
          fontFamily: 'Roboto',
          colors: '#5D6675'
        }
      }
    },
    tooltip: {
      style: {
        fontSize: '12px',
        fontFamily: 'Roboto'
      }
    }
  }
}

export const defaultBarGraphics: {
  series: Array<{ name: string; data: number[] }>
  options: ApexOptions
} = {
  series: [],
  options: {
    chart: {
      type: 'bar',
      zoom: {
        enabled: false
      },
      toolbar: {
        tools: {
          download: false
        }
      }
    },
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 3,
        borderRadiusApplication: 'end'
      }
    },
    dataLabels: {
      enabled: false
    },
    markers: {
      size: 0
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent']
    },
    title: {
      text: '',
      align: 'left',
      style: {
        fontSize: '16px',
        fontWeight: 500,
        fontFamily: 'Roboto',
        color: '#17191C'
      }
    },
    grid: {
      row: {
        colors: ['#f3f3f3', 'transparent'],
        opacity: 0.5
      }
    },
    yaxis: {
      labels: {
        style: {
          fontSize: '10px',
          fontWeight: 500,
          fontFamily: 'Roboto',
          colors: '#5D6675'
        }
      }
    },
    xaxis: {
      // tickAmount: 4,
      labels: {
        rotate: 0,
        style: {
          fontSize: '10px',
          fontWeight: 500,
          fontFamily: 'Roboto',
          colors: '#5D6675'
        }
      }
    },
    tooltip: {
      style: {
        fontSize: '12px',
        fontFamily: 'Roboto'
      }
    }
  }
}

export const defaultSimpleBarGraphics: {
  series: Array<{ data: number[] }>
  options: ApexOptions
} = {
  series: [{ data: [] }],
  options: {
    chart: {
      type: 'bar',
      zoom: {
        enabled: false
      },
      toolbar: {
        tools: {
          download: false
        }
      }
    },
    dataLabels: {
      enabled: true,
      offsetX: -5,
      textAnchor: 'end',
      style: {
        fontSize: '12px',
        fontWeight: 500,
        fontFamily: 'Roboto',
        colors: ['#FFFFFF']
      }
    },
    plotOptions: {
      bar: {
        horizontal: true,
        borderRadius: 3,
        dataLabels: {
          position: 'top'
        }
      }
    },
    markers: {
      size: 0
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent']
    },
    title: {
      text: '',
      align: 'left',
      style: {
        fontSize: '16px',
        fontWeight: 500,
        fontFamily: 'Roboto',
        color: '#17191C'
      }
    },
    // subtitle: {
    //   text: 'Analysis of desktop sales over the year', // Subtítulo
    //   align: 'left',
    //   style: {
    //     fontSize: '14px',
    //     color: '#666'
    //   }
    // },
    grid: {
      row: {
        colors: ['#f3f3f3', 'transparent'],
        opacity: 0.5
      }
    },
    yaxis: {
      labels: {
        style: {
          fontSize: '10px',
          fontWeight: 500,
          fontFamily: 'Roboto',
          colors: '#5D6675'
        }
      }
    },
    xaxis: {
      tickAmount: 4,
      labels: {
        rotate: 0,
        style: {
          fontSize: '10px',
          fontWeight: 500,
          fontFamily: 'Roboto',
          colors: '#5D6675'
        }
      }
    },
    tooltip: {
      style: {
        fontSize: '12px',
        fontFamily: 'Roboto'
      }
    }
  }
}
