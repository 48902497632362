import { i18n } from '@/common/i18n'
import { listHubApiResultSanitizer } from '@/common/sanitizers'
import { hubApiService } from '@/common/services'

import {
  AvailableUSerApiResult,
  AvailableUserApiParams,
  AvailableUserServiceParams,
  AvailableUserServiceResult,
  BulkRemoveUsersApiParams,
  BulkRemoveUsersServiceParams,
  CreateUserApiParams,
  CreateUserApiResult,
  CreateUserServiceParams,
  CreateUserServiceResult,
  ListQrCodeUsersApiResult,
  ListQrCodeUsersServiceParams,
  ListQrCodeUsersServiceResult,
  ListShortStudentApiParams,
  ListShortStudentApiResult,
  ListShortStudentServiceParams,
  ListShortStudentServiceResult,
  ListTeacherApiParams,
  ListTeacherApiResult,
  ListTeacherServiceParams,
  ListTeacherServiceResult,
  ListUserApiParams,
  ListUserApiResult,
  ListUserServiceParams,
  ListUserServiceResult,
  NewPasswordApiParams,
  NewPasswordApiResult,
  NewPasswordServiceParams,
  NewPasswordServiceResult,
  ShowUserApiResult,
  ShowUserServiceParams,
  ShowUserServiceResult,
  UpdateStudentConsentApiParams,
  UpdateStudentConsentServiceParams,
  UpdateUserApiParams,
  UpdateUserApiResult,
  UpdateUserServiceParams,
  UpdateUserServiceResult
} from './contracts'
import {
  fromUserApiSanitizer,
  listQrCodeUsersApiSanitizer,
  listShortStudentApiSanitizer,
  listTeacherApiSanitizer,
  newPasswordApiSanitizer,
  showUserFormatSanitizer
} from './sanitizers'

export async function listUserService(
  params: ListUserServiceParams
): Promise<ListUserServiceResult> {
  const {
    data: { data, account, ...result }
  } = await hubApiService<ListUserApiParams, ListUserApiResult>({
    resource: i18n().modules.hub.profiles.resources.list,
    method: 'get',
    url: '/users/v2/profiles',
    params: {
      app_id: params.appId,
      search: params.search,
      role_id: params.roleCode,
      offset: params.page,
      limit: params.perPage,
      bonds: params.bonds
    }
  })

  return {
    ...listHubApiResultSanitizer({
      ...result,
      total_pages: result.pages,
      data: fromUserApiSanitizer(data)
    }),
    account
  }
}

export async function listShortStudent({
  classroomId,
  ignoreClassroomId,
  search,
  perPage,
  page,
  institutionId,
  institutionCode
}: ListShortStudentServiceParams): Promise<ListShortStudentServiceResult> {
  const { data } = await hubApiService<ListShortStudentApiParams, ListShortStudentApiResult>({
    resource: i18n().modules.hub.classroom.resources.shortStudent,
    method: 'get',
    url: '/users/v1/students',
    params: {
      offset: page,
      limit: perPage,
      classroom_id: classroomId,
      ignore_classroom_id: ignoreClassroomId,
      search,
      institution_id: institutionId,
      institution_code: institutionCode
    }
  })

  return {
    ...listHubApiResultSanitizer({
      ...data,
      total: data.total_count,
      total_pages: data.pages,
      content_range: data.per_page,
      data: listShortStudentApiSanitizer(data.data)
    })
  }
}

export async function updateStudentConsentService(
  params: UpdateStudentConsentServiceParams
): Promise<void> {
  await hubApiService<UpdateStudentConsentApiParams, undefined>({
    resource: i18n().modules.hub.profiles.resources.updateConsent,
    method: 'put',
    url: `users/v1/profiles/${params.studentId}/consent`,
    body: {
      consent: params.consent
    }
  })
}

export async function listTeacher({
  classroomId,
  ignoreClassroomId,
  search,
  perPage
}: ListTeacherServiceParams): Promise<ListTeacherServiceResult> {
  const { data } = await hubApiService<ListTeacherApiParams, ListTeacherApiResult>({
    resource: i18n().modules.hub.users.resources.listTeacher,
    method: 'get',
    url: '/users/v1/teachers',
    params: {
      limit: perPage,
      classroom_id: classroomId,
      ignore_classroom_id: ignoreClassroomId,
      search
    }
  })

  return {
    ...listHubApiResultSanitizer({
      ...data,
      total: data.total_count,
      total_pages: data.pages,
      content_range: data.per_page,
      data: listTeacherApiSanitizer(data.data)
    })
  }
}

export async function createUserService(
  params: CreateUserServiceParams
): Promise<CreateUserServiceResult> {
  const { data } = await hubApiService<CreateUserApiParams, CreateUserApiResult>({
    resource: i18n().modules.hub.users.resources.createUser,
    method: 'post',
    url: `/users/v2/profiles`,
    body: {
      allowed_image_use_on_edtech: params.allowedImageUseOnEdtech,
      last_name: params.lastName,
      first_name: params.firstName,
      birthday: params.birthday,
      code: params.code,
      document: params.document,
      email: params.email,
      gender: params.gender,
      institution_id: params.institutionId,
      login: params.login,
      phone: params.phone,
      picture: params.picture,
      roles: params.roles,
      students: params.students
    }
  })
  return showUserFormatSanitizer(data)
}

export async function bulkRemoveUsersService(params: BulkRemoveUsersServiceParams): Promise<void> {
  await hubApiService<BulkRemoveUsersApiParams, null>({
    resource: i18n().modules.hub.users.resources.bulkRemoveUser,
    method: 'delete',
    url: '/users/v1/users/bulk',
    body: params
  })
}

export async function showUserService({
  userId
}: ShowUserServiceParams): Promise<ShowUserServiceResult> {
  const { data } = await hubApiService<null, ShowUserApiResult>({
    resource: i18n().modules.hub.users.resources.showUser,
    method: 'get',
    url: `/users/v2/profiles/${userId}`
  })

  return showUserFormatSanitizer(data)
}

export async function availableUserService({
  code,
  login,
  institutionId,
  profileId
}: AvailableUserServiceParams): Promise<AvailableUserServiceResult> {
  const { data } = await hubApiService<AvailableUserApiParams, AvailableUSerApiResult>({
    resource: i18n().modules.hub.users.resources.availableUser,
    method: 'get',
    url: '/users/v1/users/available',
    params: {
      code,
      login,
      institution_id: institutionId,
      profile_id: profileId
    }
  })

  return data
}

export async function updateUserService(
  params: UpdateUserServiceParams
): Promise<UpdateUserServiceResult> {
  console.log('updateUserService -->', params)
  const { data } = await hubApiService<UpdateUserApiParams, UpdateUserApiResult>({
    resource: i18n().modules.hub.users.resources.updateUser,
    method: 'put',
    url: `/users/v2/profiles/${params.userId}`,
    body: {
      allowed_image_use_on_edtech: params.allowedImageUseOnEdtech,
      last_name: params.lastName,
      first_name: params.firstName,
      birthday: params.birthday,
      code: params.code,
      document: params.document,
      email: params.email,
      gender: params.gender,
      institution_id: params.institutionId,
      login: params.login,
      phone: params.phone,
      picture: params.picture,
      roles: params.roles,
      students: params.students
    }
  })

  return showUserFormatSanitizer(data)
}

export async function listQrCodeUsersService({
  classroomId
}: ListQrCodeUsersServiceParams): Promise<ListQrCodeUsersServiceResult> {
  const { data } = await hubApiService<null, ListQrCodeUsersApiResult>({
    resource: i18n().modules.hub.profiles.resources.list,
    method: 'get',
    url: `/users/v1/students/qrcodes?classroom_id=${classroomId}`
  })
  return {
    ...listHubApiResultSanitizer({
      ...data,
      total: data.total_count,
      data: listQrCodeUsersApiSanitizer(data.data)
    })
  }
}

export async function newPasswordService({
  codPerfil,
  idInstituicao
}: NewPasswordServiceParams): Promise<NewPasswordServiceResult> {
  const { data } = await hubApiService<NewPasswordApiParams, NewPasswordApiResult>({
    resource: i18n().modules.hub.users.resources.availableUser,
    method: 'post',
    url: '/admin/v1/login/new_reset/password',
    body: {
      cod_perfil: codPerfil,
      id_instituicao: idInstituicao
    }
  })

  return newPasswordApiSanitizer(data)
}
