import { Div } from '@positivote/design-system/components/Div'
import { Grid } from '@positivote/design-system/components/Grid'
import { IconWrapper } from '@positivote/design-system/components/IconWrapper'
import { Image } from '@positivote/design-system/components/Image'
import { Loader } from '@positivote/design-system/components/Loader'
import { Main } from '@positivote/design-system/components/Main'
import { Select } from '@positivote/design-system/components/Select'
import { Tooltip } from '@positivote/design-system/components/Tooltip'
import { Typography } from '@positivote/design-system/components/Typography'
import { InfoIcon } from '@positivote/design-system/icons/Info'
import { useEffect, useState } from 'react'
// eslint-disable-next-line import/default
import ReactApexChart from 'react-apexcharts'

import { ButtonGroup } from '@/fixme/component/ButtonGroup'
import { SchoolOrgIcon } from '@/fixme/icons/SchoolOrg'
import { useAuth } from '@/modules/hub/auth/contexts'
import { OrganizationKind } from '@/modules/hub/organizations/contracts'
import { BaseFilter } from '@/modules/hub/reports/components/BaseFilter'
import { defaultBarGraphics, defaultLineGraphics } from '@/modules/hub/reports/constants/graphics'
import { BaseFilterAccess } from '@/modules/hub/reports/contracts/form'
import { ListAppsSummaryHookParams } from '@/modules/hub/reports/contracts/hook'
import { defaultRoles } from '@/modules/hub/reports/contracts/model'
import {
  useListActiveApplications,
  useListApplication,
  useListAppsSummary,
  useListSchoolUsers
} from '@/modules/hub/reports/hooks'

export function Managerial(): JSX.Element {
  const [showFilter, setShowFilter] = useState(false)
  const [singleAccessApplicationChangeButton, setSingleAccessApplicationChangeButton] = useState(1)
  const [usersSchoolsChangeButton, setUsersSchoolsChangeButton] = useState(1)
  const [registerChangeButton, setRegisterChangeButton] = useState(0)
  const [defaultFilter, setDefaultFilter] = useState<BaseFilterAccess>()
  const [registerParams, setRegisterParams] = useState<ListAppsSummaryHookParams['model']>({
    applicationId: 0,
    roleFilter: 'all'
  })
  const { profile } = useAuth()
  const isSchool = profile?.organization.kindId === OrganizationKind.SCHOOL

  const listActiveApplications = useListActiveApplications({
    model: {
      institutionId: profile?.organization.code as unknown as string,
      ...defaultFilter
    }
  })

  const listSchoolUsers = useListSchoolUsers({
    model: {
      institutionId: profile?.organization.code as unknown as string,
      ...defaultFilter
    }
  })

  const listAppsSummary = useListAppsSummary({
    model: {
      institutionId: profile?.organization.code as unknown as string,
      ...defaultFilter,
      applicationId: registerParams.applicationId === 0 ? undefined : registerParams.applicationId,
      roleFilter: registerParams.roleFilter === 'all' ? undefined : registerParams.roleFilter
    }
  })

  const isLoading = listActiveApplications.isFetching || listSchoolUsers.isFetching

  const listApplications = useListApplication({
    model: {
      institutionId: profile?.organization.code as unknown as string
    }
  })

  const listApplicationsFormatted = [
    { id: 0, name: 'Todas as aplicações' },
    ...(listApplications.data ?? [])
  ]

  useEffect(() => {
    if (!isLoading) {
      setShowFilter(true)
    }
  }, [isLoading])

  return (
    <Main css={{ display: 'flex', flexDirection: 'column', flex: 1, overflowX: 'hidden' }}>
      <Div
        css={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          padding: '$lg',
          overflowY: 'auto',
          gap: '$lg',
          '@sm': { padding: '$md' }
        }}
      >
        {isLoading && !showFilter ? (
          <Div
            css={{
              display: 'flex',
              flex: 1,
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <Loader size={80} />
          </Div>
        ) : (
          <>
            {showFilter && (
              <BaseFilter isLoading={isLoading} onFilter={(e) => setDefaultFilter(e)} />
            )}
            {isLoading && showFilter ? (
              <Div
                css={{
                  display: 'flex',
                  flex: 1,
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <Loader size={80} />
              </Div>
            ) : (
              <>
                <Div
                  css={{
                    flexDirection: 'column',
                    display: 'flex',
                    gap: '$lg',
                    padding: '$lg',
                    borderRadius: '$lg',
                    borderWidth: '$thin',
                    borderStyle: 'solid',
                    borderColor: '$outline-variant'
                  }}
                >
                  <Div
                    css={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center'
                    }}
                  >
                    <Typography variant="titleLarge">Aplicações ativas</Typography>
                    <ButtonGroup
                      currentPosition={singleAccessApplicationChangeButton}
                      type="bar"
                      onChangeButton={(e) => {
                        setSingleAccessApplicationChangeButton(e)
                      }}
                    />
                  </Div>

                  {singleAccessApplicationChangeButton === 0 ? (
                    <Div
                      css={{
                        display: 'flex',
                        flex: 1,
                        gap: '$lg',
                        position: 'relative',
                        alignItems: 'center',
                        justifyContent: 'center',
                        minHeight: 280,
                        marginTop: '-25px'
                      }}
                    >
                      {listActiveApplications.isFetching ? (
                        <>
                          <Loader size={80} />
                        </>
                      ) : (
                        <Div
                          css={{
                            width: '100%',
                            position: 'relative'
                          }}
                        >
                          <ReactApexChart
                            options={{
                              ...defaultBarGraphics.options,
                              title: {
                                ...defaultBarGraphics.options.title
                              },
                              colors: ['#2196F3', '#A631BA', '#FFC107'],
                              xaxis: {
                                ...defaultBarGraphics.options.xaxis,
                                categories: listActiveApplications.data?.axis,
                                labels: {
                                  ...defaultBarGraphics.options.xaxis?.labels,
                                  formatter: function (val: string) {
                                    return listActiveApplications.data &&
                                      listActiveApplications.data.axis.length >= 8 &&
                                      val.length > 15
                                      ? val.substring(0, 15) + '...'
                                      : val
                                  }
                                }
                              }
                            }}
                            series={listActiveApplications.data?.series}
                            type="bar"
                            width="100%"
                            height={280}
                          />
                        </Div>
                      )}
                    </Div>
                  ) : (
                    <Div
                      css={{
                        borderRadius: '$md',
                        borderWidth: '$thin',
                        borderStyle: 'solid',
                        borderColor: '$outline-variant'
                      }}
                    >
                      <Div
                        css={{
                          display: 'flex',
                          flex: 1,
                          borderRadius: '$md',
                          flexDirection: 'column',
                          maxHeight: 368,
                          overflow: 'auto'
                        }}
                      >
                        <Grid
                          css={{
                            display: 'flex',
                            padding: '$md'
                          }}
                        >
                          <Grid
                            xl={2.4}
                            css={{ display: 'flex', alignItems: 'center', gap: '$md' }}
                          >
                            <Typography
                              data-testid="Typography-header-discipline"
                              variant="titleSmall"
                              lineClamp={1}
                              css={{ color: '$on-surface' }}
                            >
                              Aplicações
                            </Typography>
                          </Grid>

                          <Grid xl={2.4}>
                            <Typography
                              data-testid="Typography-header-code"
                              variant="titleSmall"
                              lineClamp={1}
                              css={{ color: '$on-surface' }}
                            >
                              Usuários cadastrados
                            </Typography>
                          </Grid>
                          <Grid xl={2.4}>
                            <Typography
                              data-testid="Typography-header-base-discipline"
                              variant="titleSmall"
                              lineClamp={1}
                              css={{ color: '$on-surface' }}
                            >
                              Estudantes
                            </Typography>
                          </Grid>
                          <Grid xl={2.4}>
                            <Typography
                              data-testid="Typography-header-base-discipline"
                              variant="titleSmall"
                              lineClamp={1}
                              css={{ color: '$on-surface' }}
                            >
                              Professores
                            </Typography>
                          </Grid>
                          <Grid xl={2.4}>
                            <Typography
                              data-testid="Typography-header-base-discipline"
                              variant="titleSmall"
                              lineClamp={1}
                              css={{ color: '$on-surface' }}
                            >
                              Base de usuários
                            </Typography>
                          </Grid>
                        </Grid>

                        <Div
                          css={{
                            display: 'flex',
                            flexDirection: 'column',
                            flex: 1,
                            maxHeight: 368,
                            overflow: 'auto'
                          }}
                        >
                          {listActiveApplications.data?.dataFormatted.map((application) => (
                            <Grid
                              key={application.appName}
                              css={{
                                display: 'flex',
                                padding: '$md',
                                borderWidth: '$thin',
                                borderStyle: 'solid',
                                borderColor: '$surface-variant',
                                alignItems: 'center'
                              }}
                            >
                              <Grid
                                xl={2.4}
                                css={{ display: 'flex', alignItems: 'center', gap: '$md' }}
                              >
                                <Div css={{ display: 'flex', gap: '$sm', alignItems: 'center' }}>
                                  <Image
                                    alt="2"
                                    height={24}
                                    width={24}
                                    src={application.appIcon}
                                    css={{
                                      borderRadius: '$full',
                                      borderWidth: '$thin',
                                      borderStyle: 'solid',
                                      borderColor: '$outline-variant'
                                    }}
                                  />
                                  <Typography
                                    variant="bodySmall"
                                    css={{ color: '$on-surface-variant' }}
                                  >
                                    {application.appName}
                                  </Typography>
                                </Div>
                              </Grid>

                              <Grid xl={2.4}>
                                <Typography
                                  data-testid="Typography-header-code"
                                  variant="bodySmall"
                                  lineClamp={1}
                                  css={{ color: '$on-surface-variant' }}
                                >
                                  {application.registeredUsers}
                                </Typography>
                              </Grid>
                              <Grid xl={2.4}>
                                <Typography
                                  data-testid="Typography-header-code"
                                  variant="bodySmall"
                                  lineClamp={1}
                                  css={{ color: '$on-surface-variant' }}
                                >
                                  {application.licensedStudents}
                                </Typography>
                              </Grid>
                              <Grid xl={2.4}>
                                <Typography
                                  data-testid="Typography-header-code"
                                  variant="bodySmall"
                                  lineClamp={1}
                                  css={{ color: '$on-surface-variant' }}
                                >
                                  {application.licensedTeatchers}
                                </Typography>
                              </Grid>
                              <Grid xl={2.4}>
                                <Typography
                                  data-testid="Typography-header-code"
                                  variant="bodySmall"
                                  lineClamp={1}
                                  css={{ color: '$on-surface-variant' }}
                                >
                                  {application.registeredLicensedRatio}%
                                </Typography>
                              </Grid>
                            </Grid>
                          ))}
                        </Div>
                      </Div>
                    </Div>
                  )}
                </Div>
                {!isSchool && (
                  <Div
                    css={{
                      flexDirection: 'column',
                      display: 'flex',
                      gap: '$lg',
                      padding: '$lg',
                      borderRadius: '$lg',
                      borderWidth: '$thin',
                      borderStyle: 'solid',
                      borderColor: '$outline-variant'
                    }}
                  >
                    <Div
                      css={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                      }}
                    >
                      <Typography variant="titleLarge">Usuários por escolas</Typography>
                      <ButtonGroup
                        currentPosition={usersSchoolsChangeButton}
                        type="bar"
                        onChangeButton={(e) => {
                          setUsersSchoolsChangeButton(e)
                        }}
                      />
                    </Div>

                    {usersSchoolsChangeButton === 0 ? (
                      <Div
                        css={{
                          display: 'flex',
                          flex: 1,
                          gap: '$lg',
                          position: 'relative',
                          alignItems: 'center',
                          justifyContent: 'center',
                          minHeight: 280,
                          marginTop: '-25px'
                        }}
                      >
                        {listSchoolUsers.isFetching ? (
                          <>
                            <Loader size={80} />
                          </>
                        ) : (
                          <Div
                            css={{
                              width: '100%',
                              position: 'relative'
                            }}
                          >
                            <ReactApexChart
                              options={{
                                ...defaultBarGraphics.options,
                                title: {
                                  ...defaultBarGraphics.options.title
                                },
                                colors: ['#3F51B5', '#E91E63'],
                                xaxis: {
                                  ...defaultBarGraphics.options.xaxis,
                                  labels: {
                                    ...defaultBarGraphics.options.xaxis?.labels,
                                    formatter: function (val: string) {
                                      return listSchoolUsers.data &&
                                        listSchoolUsers.data.dataFormatted.length >= 8 &&
                                        val.length > 15
                                        ? val.substring(0, 15) + '...'
                                        : val
                                    }
                                  },
                                  categories: listSchoolUsers.data?.axis
                                }
                              }}
                              series={listSchoolUsers.data?.series}
                              type="bar"
                              width="100%"
                              height={280}
                            />
                          </Div>
                        )}
                      </Div>
                    ) : (
                      <Div
                        css={{
                          borderRadius: '$md',
                          borderWidth: '$thin',
                          borderStyle: 'solid',
                          borderColor: '$outline-variant'
                        }}
                      >
                        <Div
                          css={{
                            display: 'flex',
                            flex: 1,
                            borderRadius: '$md',
                            flexDirection: 'column',
                            maxHeight: 368,
                            overflow: 'auto'
                          }}
                        >
                          <Grid
                            css={{
                              display: 'flex',
                              padding: '$md'
                            }}
                          >
                            <Grid
                              xl={3.4}
                              css={{ display: 'flex', alignItems: 'center', gap: '$md' }}
                            >
                              <Typography
                                data-testid="Typography-header-discipline"
                                variant="titleSmall"
                                lineClamp={1}
                                css={{ color: '$on-surface' }}
                              >
                                Escolas
                              </Typography>
                            </Grid>

                            <Grid xl={2.4}>
                              <Typography
                                data-testid="Typography-header-code"
                                variant="titleSmall"
                                lineClamp={1}
                                css={{ color: '$on-surface' }}
                              >
                                Total de usuários
                              </Typography>
                            </Grid>
                            <Grid xl={2.4}>
                              <Typography
                                data-testid="Typography-header-base-discipline"
                                variant="titleSmall"
                                lineClamp={1}
                                css={{ color: '$on-surface' }}
                              >
                                Estudantes
                              </Typography>
                            </Grid>
                            <Grid xl={2.4}>
                              <Typography
                                data-testid="Typography-header-base-discipline"
                                variant="titleSmall"
                                lineClamp={1}
                                css={{ color: '$on-surface' }}
                              >
                                Professores
                              </Typography>
                            </Grid>
                            <Grid xl={1.4}>
                              <Typography
                                data-testid="Typography-header-base-discipline"
                                variant="titleSmall"
                                lineClamp={1}
                                css={{ color: '$on-surface' }}
                              >
                                Base de usuários
                              </Typography>
                            </Grid>
                          </Grid>

                          <Div
                            css={{
                              display: 'flex',
                              flexDirection: 'column',
                              flex: 1,
                              maxHeight: 368,
                              overflow: 'auto'
                            }}
                          >
                            {listSchoolUsers.data?.dataFormatted.map((school) => (
                              <Grid
                                key={school.orgHubCode}
                                css={{
                                  display: 'flex',
                                  padding: '$md',
                                  borderWidth: '$thin',
                                  borderStyle: 'solid',
                                  borderColor: '$surface-variant',
                                  alignItems: 'center'
                                }}
                              >
                                <Grid
                                  xl={3.4}
                                  css={{ display: 'flex', alignItems: 'center', gap: '$md' }}
                                >
                                  <Div css={{ display: 'flex', gap: '$sm', alignItems: 'center' }}>
                                    <IconWrapper
                                      size={24}
                                      css={{ backgroundColor: '$primary-container' }}
                                    >
                                      <SchoolOrgIcon size={14} fill="$on-primary-container" />
                                    </IconWrapper>
                                    <Typography
                                      variant="bodySmall"
                                      css={{ color: '$on-surface-variant' }}
                                    >
                                      {school.orgHubName}
                                    </Typography>
                                  </Div>
                                </Grid>

                                <Grid xl={2.4}>
                                  <Typography
                                    data-testid="Typography-header-code"
                                    variant="bodySmall"
                                    lineClamp={1}
                                    css={{ color: '$on-surface-variant' }}
                                  >
                                    {school.registeredUsersByOrg}
                                  </Typography>
                                </Grid>
                                <Grid xl={2.4}>
                                  <Typography
                                    data-testid="Typography-header-code"
                                    variant="bodySmall"
                                    lineClamp={1}
                                    css={{ color: '$on-surface-variant' }}
                                  >
                                    {school.registeredStudentsByOrg}
                                  </Typography>
                                </Grid>
                                <Grid xl={2.4}>
                                  <Typography
                                    data-testid="Typography-header-code"
                                    variant="bodySmall"
                                    lineClamp={1}
                                    css={{ color: '$on-surface-variant' }}
                                  >
                                    {school.registeredTeachersByOrg}
                                  </Typography>
                                </Grid>
                                <Grid xl={1.4}>
                                  <Typography
                                    data-testid="Typography-header-code"
                                    variant="bodySmall"
                                    lineClamp={1}
                                    css={{ color: '$on-surface-variant' }}
                                  >
                                    {school.registeredNetOrgRatio}%
                                  </Typography>
                                </Grid>
                              </Grid>
                            ))}
                          </Div>
                        </Div>
                      </Div>
                    )}
                  </Div>
                )}

                <Div
                  css={{
                    flexDirection: 'column',
                    display: 'flex',
                    gap: '$lg',
                    padding: '$lg',
                    borderRadius: '$lg',
                    borderWidth: '$thin',
                    borderStyle: 'solid',
                    borderColor: '$outline-variant'
                  }}
                >
                  <Div
                    css={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center'
                    }}
                  >
                    <Typography variant="titleLarge">Cadastros</Typography>
                    <ButtonGroup
                      type="line"
                      onChangeButton={(e) => {
                        setRegisterChangeButton(e)
                      }}
                    />
                  </Div>
                  {profile?.role.code === 'diretor' && (
                    <Div css={{ display: 'flex', gap: '$lg' }}>
                      <Select
                        label="Selecione o tipo de usuário"
                        variant="outlined"
                        optionKeyField="key"
                        optionTitleField="name"
                        value={registerParams.roleFilter}
                        options={defaultRoles}
                        css={{ width: '300px' }}
                        onChange={(option) =>
                          setRegisterParams((oldData) => ({
                            ...oldData,
                            roleFilter: option?.key
                          }))
                        }
                      />
                      <Select
                        label="Selecione a aplicação"
                        variant="outlined"
                        optionKeyField="id"
                        optionTitleField="name"
                        value={registerParams.applicationId}
                        options={listApplicationsFormatted}
                        css={{ width: '300px' }}
                        onChange={(option) =>
                          setRegisterParams((oldData) => ({
                            ...oldData,
                            applicationId: option?.id
                          }))
                        }
                      />
                    </Div>
                  )}

                  {registerChangeButton === 0 ? (
                    <Div
                      css={{
                        display: 'flex',
                        flex: 1,
                        gap: '$lg',
                        position: 'relative',
                        minHeight: 360,
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}
                    >
                      {listAppsSummary.isFetching ? (
                        <>
                          <Loader size={80} />
                        </>
                      ) : (
                        <>
                          <Div
                            css={{
                              width: '100%',
                              padding: '$lg',
                              borderRadius: '$lg',
                              borderWidth: '$thin',
                              borderStyle: 'solid',
                              borderColor: '$outline-variant'
                            }}
                          >
                            <Div
                              css={{
                                position: 'absolute',
                                top: 24,
                                right: 24,
                                zIndex: 1
                              }}
                            >
                              <Tooltip label="Representa o número total de usuários cadastrados nas aplicações a cada ano, ao longo do tempo.">
                                <InfoIcon />
                              </Tooltip>
                            </Div>
                            <ReactApexChart
                              options={{
                                ...defaultLineGraphics.options,
                                title: {
                                  ...defaultLineGraphics.options.title,
                                  text: 'Usuários cadastrados'
                                },
                                colors: ['#3F51B5'],
                                xaxis: {
                                  ...defaultLineGraphics.options.xaxis,
                                  categories: listAppsSummary.data?.axis ?? []
                                }
                              }}
                              series={[
                                {
                                  name: 'Usuários',
                                  data: listAppsSummary.data?.series ?? []
                                }
                              ]}
                              type="bar"
                              width="100%"
                              height="360"
                            />
                          </Div>
                        </>
                      )}
                    </Div>
                  ) : (
                    <Div
                      css={{
                        borderRadius: '$md',
                        borderWidth: '$thin',
                        borderStyle: 'solid',
                        borderColor: '$outline-variant'
                      }}
                    >
                      <Div
                        css={{
                          display: 'flex',
                          flex: 1,
                          borderRadius: '$md',
                          flexDirection: 'column',
                          maxHeight: 368,
                          overflow: 'auto'
                        }}
                      >
                        <Grid
                          css={{
                            display: 'flex',
                            padding: '$md'
                          }}
                        >
                          <Grid xl={4.5} css={{ display: 'flex', alignItems: 'center' }}>
                            <Typography
                              data-testid="Typography-header-discipline"
                              variant="titleSmall"
                              lineClamp={1}
                              css={{ color: '$on-surface' }}
                            >
                              Aplicações
                            </Typography>
                          </Grid>

                          <Grid xl={2.5}>
                            <Div css={{ display: 'flex', flexDirection: 'column' }}>
                              <Typography
                                data-testid="Typography-header-code"
                                variant="titleSmall"
                                lineClamp={1}
                                css={{ color: '$on-surface' }}
                              >
                                {listAppsSummary.data?.dataFormatted[0].year}
                              </Typography>
                              <Typography
                                variant="labelSmall"
                                css={{ color: '$on-surface-variant' }}
                              >
                                Usuários cadastrados
                              </Typography>
                            </Div>
                          </Grid>
                          <Grid xl={2.5}>
                            <Div css={{ display: 'flex', flexDirection: 'column' }}>
                              <Typography
                                data-testid="Typography-header-code"
                                variant="titleSmall"
                                lineClamp={1}
                                css={{ color: '$on-surface' }}
                              >
                                {listAppsSummary.data?.dataFormatted[1].year}
                              </Typography>
                              <Typography
                                variant="labelSmall"
                                css={{ color: '$on-surface-variant' }}
                              >
                                Usuários cadastrados
                              </Typography>
                            </Div>
                          </Grid>
                          <Grid xl={2.5}>
                            <Div css={{ display: 'flex', flexDirection: 'column' }}>
                              <Typography
                                data-testid="Typography-header-code"
                                variant="titleSmall"
                                lineClamp={1}
                                css={{ color: '$on-surface' }}
                              >
                                {listAppsSummary.data?.dataFormatted[2].year}
                              </Typography>
                              <Typography
                                variant="labelSmall"
                                css={{ color: '$on-surface-variant' }}
                              >
                                Usuários cadastrados
                              </Typography>
                            </Div>
                          </Grid>
                        </Grid>

                        <Div
                          css={{
                            display: 'flex',
                            flexDirection: 'column',
                            flex: 1,
                            maxHeight: 368,
                            overflow: 'auto'
                          }}
                        >
                          {Object.entries(listAppsSummary.data?.application ?? {}).map(
                            ([appName, appData]) => (
                              <Grid
                                key={appName}
                                css={{
                                  display: 'flex',
                                  padding: '$md',
                                  borderWidth: '$thin',
                                  borderStyle: 'solid',
                                  borderColor: '$surface-variant',
                                  alignItems: 'center'
                                }}
                              >
                                <Grid
                                  xl={4.5}
                                  css={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '$md'
                                  }}
                                >
                                  <Div css={{ display: 'flex', gap: '$sm', alignItems: 'center' }}>
                                    <Image
                                      alt={appData.name}
                                      height={24}
                                      width={24}
                                      src={appData.icon}
                                      css={{
                                        borderRadius: '$full',
                                        borderWidth: '$thin',
                                        borderStyle: 'solid',
                                        borderColor: '$outline-variant'
                                      }}
                                    />
                                    <Typography
                                      variant="bodySmall"
                                      css={{ color: '$on-surface-variant' }}
                                    >
                                      {appData.name}
                                    </Typography>
                                  </Div>
                                </Grid>

                                <Grid xl={2.5}>
                                  <Typography
                                    data-testid="Typography-header-code"
                                    variant="bodySmall"
                                    lineClamp={1}
                                    css={{ color: '$on-surface-variant' }}
                                  >
                                    {appData.data[0]}
                                  </Typography>
                                </Grid>
                                <Grid xl={2.5}>
                                  <Typography
                                    data-testid="Typography-header-code"
                                    variant="bodySmall"
                                    lineClamp={1}
                                    css={{ color: '$on-surface-variant' }}
                                  >
                                    {appData.data[1]}
                                  </Typography>
                                </Grid>
                                <Grid xl={2.5}>
                                  <Typography
                                    data-testid="Typography-header-code"
                                    variant="bodySmall"
                                    lineClamp={1}
                                    css={{ color: '$on-surface-variant' }}
                                  >
                                    {appData.data[2]}
                                  </Typography>
                                </Grid>
                              </Grid>
                            )
                          )}
                        </Div>
                      </Div>
                    </Div>
                  )}
                </Div>
              </>
            )}
          </>
        )}
      </Div>
    </Main>
  )
}
