import { yupResolver } from '@hookform/resolvers/yup'
import * as Dialog from '@positivote/design-system/components/Dialog'
import { FormAutocomplete } from '@positivote/design-system/components-form/Autocomplete'
import { FormContainer } from '@positivote/design-system/components-form/Container'
import { AddCircleIcon } from '@positivote/design-system/icons/AddCircle'
import { CancelIcon } from '@positivote/design-system/icons/Cancel'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import { i18n } from '@/common/i18n'
import { useAuth } from '@/modules/hub/auth/contexts'
import {
  DisciplineSelectForm,
  DisciplineSelectModelForm
} from '@/modules/hub/disciplines/contracts'
import { useListDiscipline } from '@/modules/hub/disciplines/hooks'
import { assingDisciplineValidationSchema } from '@/modules/hub/disciplines/validations'
import { Teacher } from '@/modules/hub/users/contracts'

interface AssingDisciplineDialogProps {
  isOpen: boolean
  onClose: () => void
  teacher?: Teacher
  selectDiscipline: (value: DisciplineSelectForm) => void
}

export function AssingDisciplineDialog({
  isOpen,
  onClose,
  teacher,
  selectDiscipline
}: AssingDisciplineDialogProps): JSX.Element {
  const { profile } = useAuth()

  const [searchTextDiscipline, setSearchTextDiscipline] = useState('')

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors }
  } = useForm<DisciplineSelectModelForm>({
    mode: 'onSubmit',
    resolver: async (value, ...args) =>
      yupResolver(assingDisciplineValidationSchema)(value, ...args)
  })

  const { data, isFetching: disciplineIsFetching } = useListDiscipline({
    model: {
      perPage: 300,
      search: searchTextDiscipline || undefined,
      institutionId: profile!.organizationId
    },
    queryOptions: {
      enabled: isOpen
    }
  })

  useEffect(() => {
    setValue('discipline', teacher?.courses.map((course) => course.id) ?? [])
  }, [teacher, isOpen, setValue])

  const mergedArray = [...(data?.registers ?? []), ...(teacher?.courses ?? [])]

  const newRegister = Array.from(new Map(mergedArray.map((item) => [item.id, item])).values())

  function handleClose(): void {
    reset()
    onClose()
    setSearchTextDiscipline('')
  }

  function onSubmit(model: DisciplineSelectModelForm): void {
    const disciplines = newRegister.filter((discipline) => model.discipline.includes(discipline.id))
    selectDiscipline({ disciplines })
    reset()
    onClose()
    setSearchTextDiscipline('')
  }

  return (
    <Dialog.Container xl={5} md={8} sm={10} xs={12} isOpen={isOpen} onCancel={handleClose}>
      <FormContainer formHandleSubmit={handleSubmit} onSubmit={onSubmit}>
        <Dialog.Header align="default">
          <Dialog.HeaderTitle>
            {i18n().modules.hub.disciplines.components.assingDisciplineDialog.title}
          </Dialog.HeaderTitle>
          <Dialog.HeaderCloseButton onCancel={handleClose} />
        </Dialog.Header>
        <Dialog.Content align="center">
          <Dialog.ContentText>
            {i18n().modules.hub.disciplines.components.assingDisciplineDialog.subtitle(
              teacher?.name ?? ''
            )}
          </Dialog.ContentText>
          <FormAutocomplete
            control={control}
            name="discipline"
            label={
              i18n().modules.hub.disciplines.components.assingDisciplineDialog.selectDiscipline
            }
            variant="outlined"
            errorText={errors.discipline?.message}
            isLoading={disciplineIsFetching}
            options={newRegister}
            optionKeyField="id"
            optionTitleField="name"
            // inputProps={{ autoFocus: true, onChange: handleChangeSearchTextDiscipline }}
            supportingText={
              i18n().modules.hub.disciplines.components.assingDisciplineDialog
                .canSelectMultipleOptions
            }
            multiple
            withCheckbox
          />
        </Dialog.Content>
        <Dialog.Footer align="center">
          <Dialog.FooterRefuseButton LeadingIcon={<CancelIcon size={18} />} onClick={handleClose}>
            {i18n().modules.hub.disciplines.components.assingDisciplineDialog.cancel}
          </Dialog.FooterRefuseButton>
          <Dialog.FooterAcceptButton LeadingIcon={<AddCircleIcon size={18} />} type="submit">
            {i18n().modules.hub.disciplines.components.assingDisciplineDialog.add}
          </Dialog.FooterAcceptButton>
        </Dialog.Footer>
      </FormContainer>
    </Dialog.Container>
  )
}
