import { UseQueryResult, keepPreviousData, useQuery } from '@tanstack/react-query'

import { ApplicationException } from '@/common/exceptions'
import { useErrorHandler } from '@/common/hooks'

import {
  ListAccessApplicationHookParams,
  ListAccessApplicationHookResult,
  ListAccessApplicationSchoolRolesHookParams,
  ListAccessApplicationSchoolRolesHookResult,
  ListAccessApplicationUniqueRolesHookParams,
  ListAccessApplicationUniqueRolesHookResult,
  ListAccessHookParams,
  ListAccessHookResult,
  ListAccessSummaryHookParams,
  ListAccessSummaryHookResult,
  ListAccessUniqueHookParams,
  ListAccessUniqueHookResult,
  ListActiveApplicationsHookParams,
  ListActiveApplicationsHookResult,
  ListApplicationHookParams,
  ListApplicationHookResult,
  ListAppsSummaryHookParams,
  ListAppsSummaryHookResult,
  ListChildrenHookParams,
  ListChildrenHookResult,
  ListSchoolAccessHookParams,
  ListSchoolAccessHookResult,
  ListSchoolStudentsHookParams,
  ListSchoolStudentsHookResult,
  ListSchoolUsersHookParams,
  ListSchoolUsersHookResult,
  ListUniqueApplicationHookParams,
  ListUniqueApplicationHookResult,
  ListUsersManagementHookParams,
  ListUsersManagementHookResult
} from './contracts/hook'
import {
  listAccessApplicationSchoolRolesService,
  listAccessApplicationUniqueRolesService,
  listAccessApplicationUniqueService,
  listAccessService,
  listAccessSummaryService,
  listAccessUniqueService,
  listActiveApplicationsService,
  listApplicationService,
  listAppsSummaryService,
  listChildrenService,
  listSchoolAccessService,
  listSchoolStudentsService,
  listSchoolUsersService,
  listUniqueApplicationService,
  listUsersManagementService
} from './services'

export const hookKey = 'data-viz'

export function useListAccessSummary({
  model,
  queryOptions,
  onSuccess,
  onError
}: ListAccessSummaryHookParams): UseQueryResult<ListAccessSummaryHookResult, ApplicationException> {
  const { handleError } = useErrorHandler()

  return useQuery({
    queryKey: [hookKey, 'listAccessSummary', model],
    placeholderData: keepPreviousData,
    queryFn: async () => {
      try {
        const listedAccessSummary = await listAccessSummaryService(model)
        onSuccess?.(listedAccessSummary)
        return listedAccessSummary
      } catch (error) {
        const parsedError = error as ApplicationException
        handleError({ error: parsedError })
        onError?.({ error: parsedError })
        throw parsedError
      }
    },
    ...queryOptions
  })
}

export function useListAccess({
  model,
  queryOptions,
  onSuccess,
  onError
}: ListAccessHookParams): UseQueryResult<ListAccessHookResult, ApplicationException> {
  const { handleError } = useErrorHandler()

  return useQuery({
    queryKey: [hookKey, 'listAccess', model],
    placeholderData: keepPreviousData,
    queryFn: async () => {
      try {
        const listedAccess = await listAccessService(model)
        onSuccess?.(listedAccess)
        return listedAccess
      } catch (error) {
        const parsedError = error as ApplicationException
        handleError({ error: parsedError })
        onError?.({ error: parsedError })
        throw parsedError
      }
    },
    ...queryOptions
  })
}

export function useListApplication({
  model,
  queryOptions,
  onSuccess,
  onError
}: ListApplicationHookParams): UseQueryResult<ListApplicationHookResult[], ApplicationException> {
  const { handleError } = useErrorHandler()

  return useQuery({
    queryKey: [hookKey, 'listApplication', model],
    queryFn: async () => {
      try {
        const listedApplication = await listApplicationService(model)
        onSuccess?.(listedApplication)
        return listedApplication
      } catch (error) {
        const parsedError = error as ApplicationException
        handleError({ error: parsedError })
        onError?.({ error: parsedError })
        throw parsedError
      }
    },
    ...queryOptions
  })
}

export function useListUsersManagement({
  model,
  queryOptions,
  onSuccess,
  onError
}: ListUsersManagementHookParams): UseQueryResult<
  ListUsersManagementHookResult,
  ApplicationException
> {
  const { handleError } = useErrorHandler()

  return useQuery({
    queryKey: [hookKey, 'listUsersManagement', model],
    placeholderData: keepPreviousData,
    queryFn: async () => {
      try {
        const listedUsersManagement = await listUsersManagementService(model)
        onSuccess?.(listedUsersManagement)
        return listedUsersManagement
      } catch (error) {
        const parsedError = error as ApplicationException
        handleError({ error: parsedError })
        onError?.({ error: parsedError })
        throw parsedError
      }
    },
    ...queryOptions
  })
}

export function useListUniqueApplication({
  model,
  queryOptions,
  onSuccess,
  onError
}: ListUniqueApplicationHookParams): UseQueryResult<
  ListUniqueApplicationHookResult,
  ApplicationException
> {
  const { handleError } = useErrorHandler()

  return useQuery({
    queryKey: [hookKey, 'unique-application', model],
    placeholderData: keepPreviousData,
    queryFn: async () => {
      try {
        const listedUniqueApplication = await listUniqueApplicationService(model)
        onSuccess?.(listedUniqueApplication)
        return listedUniqueApplication
      } catch (error) {
        const parsedError = error as ApplicationException
        handleError({ error: parsedError })
        onError?.({ error: parsedError })
        throw parsedError
      }
    },
    ...queryOptions
  })
}

export function useListAccessUnique({
  model,
  queryOptions,
  onSuccess,
  onError
}: ListAccessUniqueHookParams): UseQueryResult<ListAccessUniqueHookResult, ApplicationException> {
  const { handleError } = useErrorHandler()

  return useQuery({
    queryKey: [hookKey, 'access-unique', model],
    placeholderData: keepPreviousData,
    queryFn: async () => {
      try {
        const listedAccessUnique = await listAccessUniqueService(model)
        onSuccess?.(listedAccessUnique)
        return listedAccessUnique
      } catch (error) {
        const parsedError = error as ApplicationException
        handleError({ error: parsedError })
        onError?.({ error: parsedError })
        throw parsedError
      }
    },
    ...queryOptions
  })
}

// export function useListAccessTotal({
//   model,
//   queryOptions,
//   onSuccess,
//   onError
// }: ListAccessTotalHookParams): UseQueryResult<ListAccessTotalHookResult, ApplicationException> {
//   const { handleError } = useErrorHandler()

//   return useQuery({
//     queryKey: [hookKey, 'access-total', model],
//     placeholderData: keepPreviousData,
//     queryFn: async () => {
//       try {
//         const listedAccessTotal = await listAccessTotalService(model)
//         onSuccess?.(listedAccessTotal)
//         return listedAccessTotal
//       } catch (error) {
//         const parsedError = error as ApplicationException
//         handleError({ error: parsedError })
//         onError?.({ error: parsedError })
//         throw parsedError
//       }
//     },
//     ...queryOptions
//   })
// }

export function useAccessApplicationUnique({
  model,
  queryOptions,
  onSuccess,
  onError
}: ListAccessApplicationHookParams): UseQueryResult<
  ListAccessApplicationHookResult,
  ApplicationException
> {
  const { handleError } = useErrorHandler()

  return useQuery({
    queryKey: [hookKey, 'AccessApplicationUnique', model],
    placeholderData: keepPreviousData,
    queryFn: async () => {
      try {
        const listedAccessApplicationUnique = await listAccessApplicationUniqueService(model)
        onSuccess?.(listedAccessApplicationUnique)
        return listedAccessApplicationUnique
      } catch (error) {
        const parsedError = error as ApplicationException
        handleError({ error: parsedError })
        onError?.({ error: parsedError })
        throw parsedError
      }
    },
    ...queryOptions
  })
}

export function useListSchoolAccess({
  model,
  queryOptions,
  onSuccess,
  onError
}: ListSchoolAccessHookParams): UseQueryResult<ListSchoolAccessHookResult, ApplicationException> {
  const { handleError } = useErrorHandler()

  return useQuery({
    queryKey: [hookKey, 'listSchoolAccess', model],
    placeholderData: keepPreviousData,
    queryFn: async () => {
      try {
        const listedSchoolAccess = await listSchoolAccessService(model)
        onSuccess?.(listedSchoolAccess)
        return listedSchoolAccess
      } catch (error) {
        const parsedError = error as ApplicationException
        handleError({ error: parsedError })
        onError?.({ error: parsedError })
        throw parsedError
      }
    },
    ...queryOptions
  })
}

export function useListAccessApplicationUniqueRoles({
  model,
  queryOptions,
  onSuccess,
  onError
}: ListAccessApplicationUniqueRolesHookParams): UseQueryResult<
  ListAccessApplicationUniqueRolesHookResult,
  ApplicationException
> {
  const { handleError } = useErrorHandler()

  return useQuery({
    queryKey: [hookKey, 'ListAccessApplicationUniqueRoles', model],
    placeholderData: keepPreviousData,
    queryFn: async () => {
      try {
        const listedAccessApplicationUniqueRoles =
          await listAccessApplicationUniqueRolesService(model)
        onSuccess?.(listedAccessApplicationUniqueRoles)
        return listedAccessApplicationUniqueRoles
      } catch (error) {
        const parsedError = error as ApplicationException
        handleError({ error: parsedError })
        onError?.({ error: parsedError })
        throw parsedError
      }
    },
    ...queryOptions
  })
}

export function useListChildren({
  model,
  queryOptions,
  onSuccess,
  onError
}: ListChildrenHookParams): UseQueryResult<ListChildrenHookResult, ApplicationException> {
  const { handleError } = useErrorHandler()

  return useQuery({
    queryKey: [hookKey, 'ListChildren', model],
    placeholderData: keepPreviousData,
    queryFn: async () => {
      try {
        const listedChildrenService = await listChildrenService(model)
        onSuccess?.(listedChildrenService)
        return listedChildrenService
      } catch (error) {
        const parsedError = error as ApplicationException
        handleError({ error: parsedError })
        onError?.({ error: parsedError })
        throw parsedError
      }
    },
    ...queryOptions
  })
}

export function useListAccessApplicationSchoolRoles({
  model,
  queryOptions,
  onSuccess,
  onError
}: ListAccessApplicationSchoolRolesHookParams): UseQueryResult<
  ListAccessApplicationSchoolRolesHookResult,
  ApplicationException
> {
  const { handleError } = useErrorHandler()

  return useQuery({
    queryKey: [hookKey, 'listAccessApplicationSchoolRoles', model],
    placeholderData: keepPreviousData,
    queryFn: async () => {
      try {
        const listedAccessApplicationSchoolRolesService =
          await listAccessApplicationSchoolRolesService(model)
        onSuccess?.(listedAccessApplicationSchoolRolesService)
        return listedAccessApplicationSchoolRolesService
      } catch (error) {
        const parsedError = error as ApplicationException
        handleError({ error: parsedError })
        onError?.({ error: parsedError })
        throw parsedError
      }
    },
    ...queryOptions
  })
}

export function useListActiveApplications({
  model,
  queryOptions,
  onSuccess,
  onError
}: ListActiveApplicationsHookParams): UseQueryResult<
  ListActiveApplicationsHookResult,
  ApplicationException
> {
  const { handleError } = useErrorHandler()

  return useQuery({
    queryKey: [hookKey, 'listActiveApplications', model],
    placeholderData: keepPreviousData,
    queryFn: async () => {
      try {
        const listedActiveApplications = await listActiveApplicationsService(model)
        onSuccess?.(listedActiveApplications)
        return listedActiveApplications
      } catch (error) {
        const parsedError = error as ApplicationException
        handleError({ error: parsedError })
        onError?.({ error: parsedError })
        throw parsedError
      }
    },
    ...queryOptions
  })
}

export function useListSchoolUsers({
  model,
  queryOptions,
  onSuccess,
  onError
}: ListSchoolUsersHookParams): UseQueryResult<ListSchoolUsersHookResult, ApplicationException> {
  const { handleError } = useErrorHandler()

  return useQuery({
    queryKey: [hookKey, 'listSchoolUsers', model],
    placeholderData: keepPreviousData,
    queryFn: async () => {
      try {
        const listedSchoolUsers = await listSchoolUsersService(model)
        onSuccess?.(listedSchoolUsers)
        return listedSchoolUsers
      } catch (error) {
        const parsedError = error as ApplicationException
        handleError({ error: parsedError })
        onError?.({ error: parsedError })
        throw parsedError
      }
    },
    ...queryOptions
  })
}

export function useListAppsSummary({
  model,
  queryOptions,
  onSuccess,
  onError
}: ListAppsSummaryHookParams): UseQueryResult<ListAppsSummaryHookResult, ApplicationException> {
  const { handleError } = useErrorHandler()

  return useQuery({
    queryKey: [hookKey, 'listAppsSummary', model],
    placeholderData: keepPreviousData,
    queryFn: async () => {
      try {
        const listedAppsSummary = await listAppsSummaryService(model)
        onSuccess?.(listedAppsSummary)
        return listedAppsSummary
      } catch (error) {
        const parsedError = error as ApplicationException
        handleError({ error: parsedError })
        onError?.({ error: parsedError })
        throw parsedError
      }
    },
    ...queryOptions
  })
}

export function useSchoolStudents({
  model,
  queryOptions,
  onSuccess,
  onError
}: ListSchoolStudentsHookParams): UseQueryResult<
  ListSchoolStudentsHookResult,
  ApplicationException
> {
  const { handleError } = useErrorHandler()

  return useQuery({
    queryKey: [hookKey, 'listAppsSummary', model],
    placeholderData: keepPreviousData,
    queryFn: async () => {
      try {
        const listedSchoolStudents = await listSchoolStudentsService(model)
        onSuccess?.(listedSchoolStudents)
        return listedSchoolStudents
      } catch (error) {
        const parsedError = error as ApplicationException
        handleError({ error: parsedError })
        onError?.({ error: parsedError })
        throw parsedError
      }
    },
    ...queryOptions
  })
}
